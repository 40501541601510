<template>
	<div class='wxlogincover' v-if="isWxLogin">
		<div class="logincom">
			<i class="el-icon-close closebtn" @click="closeFun"></i>
			<p class="dltit">手机号绑定</p>
			<form class="loginf" ref="form" :model="form">
				<div class="tel">
					<p>+86</p>
					<input placeholder="请输入手机号" maxlength="11" v-model="form.tel" />
				</div>
				<div class="yzcode">
					<input placeholder="请输入验证码" maxlength="6" v-model="form.code" />
					<p v-if="isCode" @click="getCode">发送验证码</p>
					<p v-else>重新发送 {{ count }}s</p>
				</div>
				<div class="loginBtn" @click="bindPhone">立即绑定</div>
			</form>
		</div>
	</div>
</template>

<script>
import store from "../store";
import { sendSms, bindLogin } from "@/util/api/api.js"
export default {
	props: {
		isWxLogin: {
			type: Boolean,
		},
	},
	data() {
		return {
			form: {
				tel: '',
				code: '',
			},
			isCode: true,
			count: '',
			timer: null,
		}
	},
	created() {

	},
	methods: {
		closeFun() {
			this.$emit('closeBindPhone', false)
		},
		// 绑定事件
		async bindPhone() {
			let flag = await this._bindLogin()
			if (flag) {
				this.closeFun()
				this.$emit('getUser', true)
			}
		},
		// 获取短信验证码
		async getCode() {
			if (this.form.tel && this.form.tel.length == 11) {
				try {
					let params = {
						mobile: this.form.tel,
						type: 1
					}
					let res = await sendSms(params)
					if (res.resultCode === '000000' && res.data) {
						let TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.isCode = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.isCode = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}
				} catch (error) {
					console.log(error)
				}

			} else {
				this.$message.error('请输入手机号');
			}
		},

		// 绑定手机号
		async _bindLogin() {
			// let storeInfo = JSON.parse(localStorage.getItem("storeInfo"))
			// let userInfo = storeInfo.userInfo
			let userInfo = store.state.userInfo
			// let userInfo = storeInfo.userInfo
			try {
				let params = {
					account: this.form.tel,
					codeValue: this.form.code,
					unionid: userInfo.unionid
				}
				let res = await bindLogin(params)
				console.log(res)
				if (res.resultCode === '000000' && res.data) {
					store.dispatch('setUserInfo', res.data)
					store.dispatch('setToken', res.data.token)
					store.dispatch('setShowLogin', true)
					store.dispatch('showBindPhone', true)
				}
				this.$message.success('手机号绑定成功')
				return true
			} catch (error) {
				console.log(error)
				return false
			}
		}
	},
}
</script>

<style lang="less">
.wxlogincover {
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;

	.logincom {
		width: 500px;
		height: 750px;
		background: url(../assets/imgs/wxbg.png) no-repeat;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -375px;
		margin-left: -250px;

		.closebtn {
			color: #fff;
			cursor: pointer;
			width: 16px;
			position: absolute;
			top: 30px;
			right: 30px;
		}

		.dltit {
			text-align: center;
			font-size: 32px;
			margin: 40px auto 70px;
			color: #fff;
		}


		.loginf {
			color: #fff;

			.zd {
				margin-left: 50px;

				.el-checkbox__label {
					color: #fff;
				}

				.el-checkbox__inner {
					background-color: #B586F2 !important;
					border-color: #B586F2 !important;
				}

				// .el-checkbox__input{
				// 	.is-checked{
				// 		background-color:#B586F2 !important;
				// 	}
				// }
			}

			div {
				width: 400px;
				height: 64px;
				background: rgba(255, 255, 255, 0.2);
				border-radius: 54px;
				margin: 0 auto 24px;
				overflow: hidden;
				line-height: 64px;
				font-size: 24px;

				input {
					color: #fff;
					font-size: 24px;
					border: none;
					height: 23px;
					margin: 21px 0px 0 0;
					line-height: 23px;
					float: left;
					background: none;
					outline: none;
					padding-left: 30px;

					&::-webkit-input-placeholder {
						color: #947fb0;
					}
				}
			}

			.tel {
				p {
					float: left;
					font-size: 24px;
					width: 103px;
					text-align: center;
					border-right: 1px solid #725d93;
					height: 23px;
					margin: 21px 00px 0 0;
					line-height: 23px;
				}

				input {
					width: 236px;
				}
			}

			.yzcode {
				position: relative;

				p {
					position: absolute;
					top: 16px;
					right: 23px;
					width: 96px;
					height: 32px;
					border-radius: 16px;
					border: 1px solid #a593be;
					text-align: center;
					line-height: 32px;
					font-size: 14px;
					cursor: pointer;
				}
			}

			.loginBtn {
				background: linear-gradient(to right, #705DFF, #B586F2);
				font-size: 24px;
				text-align: center;
				cursor: pointer;
				margin-top: 290px;
			}
		}
	}
}
</style>
