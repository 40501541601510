<template>
	<div id="app" name="app">
		<el-container class="bgcolor d-flex flex-column" v-if="$route.meta.showHeader">
			<!-- 顶部导航栏 -->
			<div class="topbar" :class="{ topBar: !isShowTab }">
				<!-- 顶栏容器 -->
				<el-header>
					<div class="logo" @click="openHomeHandle">
						<img src="./assets/imgs/logo.png" alt />
					</div>
					<el-menu v-if="isRefresh && isShowTab" :default-active="activeIndex" class="el-menu-demo"
						mode="horizontal" router>
						<el-menu-item index="/">首页</el-menu-item>
						<el-menu-item index="/hall">大厅</el-menu-item>
						<el-menu-item index="/god">大神</el-menu-item>
						<el-menu-item index="/shoppingMall">商城</el-menu-item>
						<el-menu-item index="/recharge">充值</el-menu-item>
					</el-menu>
					<div class="searchCss" v-if="isRefresh && isShowTab">
						<el-input placeholder="支持房间名/ID/昵称" prefix-icon="el-icon-search" v-model="search"
							@keyup.enter.native="searchClick">
						</el-input>
					</div>
				</el-header>
				<!-- 顶栏容器END -->
				<div class="nav" v-if="isRefresh">
					<ul>
						<li class="roompr" v-if="isShowTab">
							<div class="navli navli1"></div>
							<p>快速进房</p>
							<RoomList :quickRoomList="quickRoomList" />
						</li>
						<li @click="goMessage" v-if="isShowTab">
							<div class="navli navli2"></div>
							<p>消息</p>
						</li>
						<li class="privateBox" @click="openPrivateChat" v-if="isShowPrivate">
							<div class="navli navlixx"></div>
							<p>私信</p>
							<div class="count" v-if="messageUnreadCount && messageUnreadCount > 0">{{
								messageUnreadCount && messageUnreadCount > 99 ? '99+' : messageUnreadCount }}</div>
						</li>
						<li v-if="!isLogin" @click="loginFun">
							<div class="navli navli3"></div>
							<p>登录/注册</p>
						</li>
						<li v-else-if="userInfo" class="lipr">
							<div class="userBox">
								<img v-if="userInfo.imgUrl" :src="userInfo.imgUrl" class="userimg" />
								<img v-else src="@/assets/imgs/avatar.png" class="userimg" />
								<div class="userinfo">
									<div class="userT">
										<p>{{ userInfo.nickname }}</p>
										<div class="grade">
											<img :src="userInfo.levelImg" alt="">
										</div>
									</div>
									<div class="userB">
										<p>UID：{{ userInfo.uid }}</p><i class="el-icon-caret-bottom"></i>
									</div>
								</div>
							</div>

							<UserInfo v-if="userInfo" :userInfo="userInfo" :userLeveInfo="userLeveInfo" :balance="balance"
								@exit="logout" />
						</li>
					</ul>
				</div>
			</div>
			<!-- 顶部导航栏END -->

			<!-- 右边浮窗 -->
			<FloatingWindow v-if="isRefresh && isShowTab" />
			<!-- 主要区域容器 -->
			<!-- <el-main> -->
			<div class="main d-flex flex-column flex-1" v-if="isRefresh">
				<keep-alive>
					<router-view v-if="$route.meta.keepAlive" ref="mainRef"></router-view>
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive" ref="mainRef"></router-view>
			</div>
			<!-- </el-main> -->
			<!-- 主要区域容器END -->

			<!-- 底栏容器 -->
			<el-footer v-if="isShowTab">
				<div class="footer" v-if="isRefresh">
					<ul class="webinfo">
						<li><img src="./assets/imgs/QQ.png" />
							<p>客服QQ：1058127957</p>
							<p><span>咨询时间：7X24小时</span></p>
						</li>
						<li @click="agreeHandle('agreeView')"><span>用户协议</span>
						</li>
						<li @click="agreeHandle('privacyPolicy')"><span>隐私政策</span>
						</li>
						<li @click="agreeHandle('about')"><span>关于我们</span></li>
					</ul><br />
					<div class="comInfo">
						<span>公司地址：广西壮族自治区南宁市高新区工业园创业路东面罗赖路北面低压检验楼11层1106-3号</span>
						<span>公司全称：广西牧纯科技有限公司</span>
						<a href="https://beian.miit.gov.cn" target="_blank">备案号：桂ICP备2023000823号-1</a>
					</div><br />
					<div class="comInfo">
						<span>增值电信业务经营许可证：桂B2-20230344</span>
						<img src="./assets/imgs/ghs.png" />
						<span>公安备案号：45010702002010</span>
					</div>
					<p class="copyright">Copyright 2013-2023 MCKJ Corporation, All Rights Reserved</p>
				</div>
			</el-footer>
			<!-- 底栏容器END -->
		</el-container>

		<!-- 登录/注册弹框 -->
		<LoginCom :isLogin="loginModal" @closeLogin="closeLogin" @getUser="getUserinfo" />
		<!-- 私聊弹窗 -->
		<PrivateChat v-if="isPrivateChat" :isPrivateChat="isPrivateChat" :chatUserInfo="chatUserInfo"
			@closePrivateChat="closePrivateChat" />
		<!-- 绑定手机号 -->
		<BindPhoneNumber :isWxLogin="isShowBindPhone" @closeBindPhone="closeBindPhone" @getUser="getUserinfo" />
		<!-- 实名认证 -->
		<AuthenticationPop :isShowAuth="isShowAuth" @closeAuthHandle="closeAuthHandle" />
		<!-- 萌新驾到 -->
		<mengXinPop :isShowMengXin="isShowMengXin" :mengXinInfo="mengXinInfo" @closeMengXin="closeMengXin" />
		<!-- 告白墙跑马灯 -->
		<confessionWallCard></confessionWallCard>
		<router-view v-if="!$route.meta.showHeader"></router-view>
	</div>
</template>

<script>
import {
	mapActions
} from "vuex";
import PrivateChat from '@/components/privateChat.vue'
import UserInfo from '@/components/UserInfo.vue';
import RoomList from '@/components/RoomList.vue';
import LoginCom from '@/components/LoginCom.vue';
import FloatingWindow from '@/components/FloatingWindow.vue'
import BindPhoneNumber from '@/components/BindPhoneNumber.vue';
import AuthenticationPop from '@/components/AuthenticationPop.vue';
import mengXinPop from '@/components/mengXinPop.vue';
import confessionWallCard from '@/components/confessionWallCard.vue';

import * as RongIMLib from '@rongcloud/imlib-next'
import RongCloudApi from '@/util/rongCloudApi.js'

import {
	refresh,
	wechatLogin,
	getLeveList,
	getBalance,
	getRoomTypeList,
	getQuickRoomList,
	isLogin,
	getConfigWord
} from "@/util/api/api.js"
import { deepClone, replaceKeywords } from "@/util/util.js"
import bus from '@/util/eventBus.js'

let Events = null

const worldRoomId = 'suixin2025'
export default {
	components: {
		UserInfo, RoomList, LoginCom, BindPhoneNumber, FloatingWindow, AuthenticationPop, PrivateChat, mengXinPop, confessionWallCard
	},
	beforeUpdate() {
		this.activeIndex = this.$route.path;
	},
	data() {
		return {
			isShowTab: true,// 是否展示菜单tab等
			isShowPrivate: false,// 顶部nav是否展示私信按钮
			activeIndex: "", // 头部导航栏选中的标签
			search: "", // 搜索条件
			loginModal: false,
			isLogin: false,
			isShowBindPhone: false,// 绑定手机号弹窗
			isShowAuth: false,// 实名认证弹窗
			userInfo: null, // 用户信息
			isRefresh: false, // 是否拉取最新用户信息成功（存在token则拉最新信息，不存在则直接返回true）
			balance: {
				diamond: 0,
				coin: 0
			},// 用户余额
			userLeveInfo: null,//用户等级信息
			register: false, // 是否显示注册组件
			visible: false, // 是否退出登录
			quickRoomList: [], // 快速进入房间列表
			messageUnreadCount: this.$store.state.messageUnreadCount || 0,// 私信未读数量
			isPrivateChat: false,// 是否展示聊天弹窗
			chatUserInfo: null,// 私聊对象信息
			isShowMengXin: false,// 是否展示萌新驾到
			mengXinInfo: null,// 萌新信息
		};
	},
	watch: {
		"$store.state.userInfo": {
			handler(newVal) {
				console.log('监听数据更新：', newVal);
				this.userInfo = newVal
				this.openHeartbeat()
			}
		},
		"$store.state.showLogin": {
			deep: true,
			handler(newVal) {
				console.log('监听数据更新：', newVal);
				this.loginModal = newVal

				if (this.heartbeatTimer) {
					clearInterval(this.heartbeatTimer)
					this.heartbeatTimer = null
					console.log('停止心跳')
				}
			}
		},
		"$store.state.showBindPhone": {
			deep: true,
			handler(newVal) {
				console.log('监听数据更新：', newVal);
				this.isShowBindPhone = newVal
			}
		},
		"$store.state.showAuth": {
			deep: true,
			handler(newVal) {
				console.log('监听数据更新：', newVal);
				this.isShowAuth = newVal
			}
		},
		"$store.state.balance": {
			deep: true,
			handler(newVal) {
				console.log('监听数据更新：', newVal);
				this.balance = newVal
			}
		},
		"$store.state.messageUnreadCount": {
			deep: true,
			handler(newVal) {
				this.messageUnreadCount = newVal
			}
		},
		async $route(to) {
			console.log(to)
			if (to.path.indexOf('/setup') >= 0 || to.path.indexOf('/room') >= 0 || to.path.indexOf('/fleetRoom') >= 0) {
				this.isShowTab = false
				this.isShowPrivate = to.path.indexOf('/room') >= 0 || to.path.indexOf('/fleetRoom') >= 0
			} else {
				this.isShowTab = true
			}

			// 判断app跳转到活动充值页面，存储token
			if (to.path.indexOf('/activity') >= 0) {
				console.log(to.query)
				!!this.$route.query.t && await this.setToken(to.query.t);
			}
		}
	},
	async created() {
		console.log('app初始化')
		// 禁用双指放大
		document.addEventListener('touchstart', function (event) {
			if (event.touches.length > 1) {
				event.preventDefault()
			}
		})
		// 禁用双击放大
		let lastTouchEnd = 0
		document.addEventListener(
			'touchend',
			function (event) {
				const now = new Date().getTime()
				if (now - lastTouchEnd <= 300) {
					event.preventDefault()
				}
				lastTouchEnd = now
			},
			false
		)
		document.addEventListener('gesturestart', function (event) {
			event.preventDefault()
		})

		// 判断app跳转到活动充值页面，存储token
		if (this.$route.path.indexOf('/activity') >= 0) {
			console.log(this.$route.query)
			!!this.$route.query.t && await this.setToken(this.$route.query.t);
		}

		// 初始化融云IM
		await this.initRongCloud()
		// 获取快速进房列表
		await this._getQuickRoomList()
		this._getConfigWord()

		let storeInfo = JSON.parse(localStorage.getItem("storeInfo"))
		console.log(storeInfo)
		// 获取浏览器localStorage，判断用户是否已经登录
		if (storeInfo && storeInfo.token) {
			await this.initData()
		} else {
			let query = this.getUrlParams()
			// 微信登录回调
			if (query && query.code) {
				await this._wechatLogin(query.code)
				return
			}
			this.isRefresh = true
			if (this.heartbeatTimer) {
				clearInterval(this.heartbeatTimer)
				this.heartbeatTimer = null
			}
		}

		// 监听私聊弹窗
		bus.$on('watchPrivateChat', data => {
			// 未登录情况
			if (!this.userInfo) {
				this.setShowLogin(true)
				return
			}

			// 未实名认证
			if (!this.userInfo.cardId) {
				this.setShowAuth(true);
				return
			}

			console.log("私聊弹窗事件监听:", data)
			if (data) {
				let { isPrivateChat, chatUserInfo } = data
				this.chatUserInfo = {}
				if (chatUserInfo) {
					for (let key in chatUserInfo) {
						this.$set(this.chatUserInfo, key, chatUserInfo[key])
					}
				}
				this.isPrivateChat = isPrivateChat
			}
		})
	},
	methods: {
		// 获取url的参数
		getUrlParams() {
			const params = {};
			const url = window.location.href;
			const queryStringIndex = url.indexOf('?');
			if (queryStringIndex !== -1) {
				const queryString = url.substring(queryStringIndex + 1);
				const pairs = queryString.split('&');

				pairs.forEach(pair => {
					const [key, value] = pair.split('=');
					if (key) {
						params[decodeURIComponent(key)] = value ? decodeURIComponent(value) : '';
					}
				});
			}
			console.log('解析的 URL 参数:', params);
			return params;
		},

		// 初始化
		async initData() {
			// 拉取最新数据
			await this._refresh()
			// 获取单聊会话未读数量
			this.getTotalUnreadCount()
			this._getLeveList()
			this._getBalance()
			this.openHeartbeat()
			// 加入世界聊天室
			this.joinWordRoom()
		},
		// 开启心跳—登录状态是否正常
		async openHeartbeat() {
			if (this.heartbeatTimer) {
				return
			}
			this.heartbeatTimer = setInterval(async () => {
				try {
					let res = await isLogin()
					if (res.resultCode == '000000') {
						console.log('心跳正常')
					}
				} catch (error) {
					console.log(error)
				}
			}, 60000);
		},
		// 获取聊天过滤关键词
		async _getConfigWord() {
			try {
				let res = await getConfigWord()
				if (res.resultCode == '000000') {
					this.setKeywords(res.data.list)
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 打开首页
		openHomeHandle() {
			if (this.$route.path == '/') {
				// 刷新首页
				location.reload()
			} else {
				try {
					// 打开首页
					let routeData = this.$router.resolve('/');
					window.open(routeData.href, '_blank');
				} catch (error) {
					console.log(error)
				}

			}
		},

		/**
		 * 初始化融云配置
		 */
		initRongCloud() {
			// 判断是否已经存在监听，存在则不处理
			if (Events) {
				return
			}
			Events = RongIMLib.Events
			console.log('初始化融云配置')

			/**
			 * 正在链接的事件状态
			 */
			RongIMLib.addEventListener(Events.CONNECTING, () => {
				console.log('正在链接...')
			})

			/**
			 * 链接到服务器会触发这个事件
			 */
			RongIMLib.addEventListener(Events.CONNECTED, () => {
				console.log('连接成功')
			})

			/**
			 * 手动调用 disconnect 方法或者用户被踢下线 会触发这个事件
			 */
			RongIMLib.addEventListener(Events.DISCONNECT, (code) => {
				console.log('连接中断，需要业务层进行重连处理 ->', code)
			})

			/**
			 * 链接出问题时，内部进行重新链接，会触发这个事件
			 */
			RongIMLib.addEventListener(Events.SUSPEND, (code) => {
				console.log('链接中断，SDK 会尝试重连，业务层无需关心')
				// 5.1.2 版本开始，事件回调中会引起中断的 code 状态码
				console.log(`code -> ${code}`)
			})

			/**
			 * 收到会话变更通知
			 */
			RongIMLib.addEventListener(Events.CONVERSATION, (res) => {
				console.log('收到会话变更通知:', res)
			})

			// 监听消息数据
			const listener = (evt) => {
				console.log('融云监听:', evt)
				// 消息
				if (evt.messages && evt.messages.length > 0) {
					evt.messages.forEach(item => {
						if (item && item.content && item.content.msgFields) {
							let data = item.content.msgFields
							// conversationType: 1、单聊 4、聊天室 6、系统消息
							console.log(data)

							//  聊天室天消息
							if (item.conversationType == 4) {
								if (item.targetId == worldRoomId) {
									// 告白推送
									if (data.action == 'confession') {
										bus.$emit('watchConfession', { ...data })
									} else {
										// 文本消息 过滤关键词
										if (data.action == 'im' && data.type == 1) {
											let _value = replaceKeywords(data.value, this.$store.state.keywords)
											data.value = _value
										}
										// 世界聊天室消息
										this.addWorldRoomMessage({
											...data,
											sentTime: item.sentTime
										})
									}
								} else {
									// 非世界聊天室消息（直播间、车队）
									bus.$emit('watchRongCloudMessage', { messages: { ...item } })
								}
								return
							}

							//  单聊消息 &&  (消息 || 礼物信息)
							if (item.conversationType == 1 && (data.action == 'im' || data.action == 'gift')) {
								// 获取私聊消息数量
								this.getTotalUnreadCount()
								// 更新私聊信息
								bus.$emit('watchPrivateChatUpdate', { item, data })
								return
							}

							// 萌新驾到推送消息（系统消息 && 非离线消息 && 萌新驾到）
							if (item.conversationType == 6 && !item.isOffLineMessage && data.action == 'newp') {
								this.mengXinInfo = {}
								if (data) {
									for (let key in data) {
										this.$set(this.mengXinInfo, key, data[key])
									}
								}
								this.isShowMengXin = true
								return
							}

							// 封号推送消息（系统消息 && 非离线消息 && 封号）
							if (item.conversationType == 6 && !item.isOffLineMessage && data.action == 'blockedAccount') {
								// 通过 $refs 获取子组件实例，并调用其方法
								const mainRef = this.$refs.mainRef
								const routeName = this.$route.name

								// 检查子组件是否为指定的组件
								if (routeName === 'Room' || routeName === 'FleetRoom') {
									// 调用指定组件的方法
									console.log('调用指定组件的方法')
									mainRef.handleBeforeUnload();
									// 修改vuex的showLogin状态,显示登录组件
									this.visible = false;
									this.isLogin = false
									this.setUserInfo(null);
									this.setToken('');
									this.setShowLogin(true);
									this.setShowBindPhone(false)
								}
								return
							}

							// 系统消息
							if (item.conversationType == 6) {
								bus.$emit('watchRongCloudMessage', { messages: { ...item } })
							}
						}

					})
				}

				// KV
				if (evt.updatedEntries) {
					bus.$emit('watchRongCloudMessage', { ...evt })
				}

				// 其他端加入
				if (evt.chatroomNotifyMultiLoginSync) {
					bus.$emit('watchRongCloudMessage', { ...evt })
				}

				if (evt.rejoinedRoom) {
					console.log('SDK 内部重连聊天室信息:', evt.rejoinedRoom)
				}
				if (evt.userChange) {
					console.log('加入退出的用户通知:', evt.userChange)
				}
				if (evt.chatroomDestroyed) {
					console.log('聊天室销毁:', evt.chatroomDestroyed)
				}
			};
			/**
			 * 监听消息数据
			 */
			RongIMLib.addEventListener(Events.MESSAGES, listener)
			RongIMLib.addEventListener(Events.CHATROOM, listener)
		},

		/**
		 * 融云-加入世界聊天室
		 */
		async joinWordRoom() {
			try {
				await RongCloudApi.joinChatRoom('suixin2025')
			} catch (error) {
				console.log('加入世界聊天室失败：', error)
			}
		},

		// 获取单聊会话未读数量
		async getTotalUnreadCount() {
			try {
				const includeMuted = false
				const conversationTypes = [RongIMLib.ConversationType.PRIVATE]
				let res = await RongIMLib.getTotalUnreadCount(includeMuted, conversationTypes)
				if (res.code === 0) {
					console.log(res.code, res.data)
					// 更新私信未读数量
					this.setMessageUnreadCount(res.data);
					this.messageUnreadCount = res.data
					console.log('更新私聊未读消息数量：', this.messageUnreadCount)
				} else {
					console.log(res.code, res.msg)
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 打开私聊聊天框
		openPrivateChat() {
			// 未登录情况
			if (!this.userInfo) {
				this.setShowLogin(true)
				return
			}

			// 未实名认证
			if (!this.userInfo.cardId) {
				this.setShowAuth(true);
				return
			}

			this.chatUserInfo = null
			this.isPrivateChat = true
		},

		// 关闭聊天弹窗
		closePrivateChat() {
			this.isPrivateChat = false
		},

		// 微信登录
		async _wechatLogin(code) {
			try {
				let params = {
					type: 1,
					code: code
				}
				let res = await wechatLogin(params)
				// 未绑定手机号
				if (res.resultCode == '100013') {
					this.setUserInfo(res.data);
					return
				}

				// 微信code失效
				if (res.resultCode == '100012') {
					console.log('微信code失效', res.resultCode)
					return
				}

				if (res.resultCode == '000000' && res.data) {
					// 更新用户token
					this.setToken(res.data.token);

					// 获取重定向路径并清除 URL 中的 code 参数
					const redirectPath = this.$route.path || '/';
					this.$router.replace({ path: redirectPath });

					await this.initData()
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 获取最新用户信息内容
		async _refresh() {
			try {
				let res = await refresh()
				if (res.resultCode === '000000' && res.data) {
					this.userInfo = {
						...res.data,
					}
					this.isLogin = true
					// 更新用户信息
					this.setUserInfo(this.userInfo);

					// 建立融云链接
					if (this.userInfo.rongcloudToken) {
						const appkey = process.env.NODE_ENV == "product" ? 'z3v5yqkbzgrw0' : 'lmxuhwagl3jsd'
						// 每次打开先清除融云的sessionStorage
						sessionStorage.clear(`sync-chrm-${appkey}-${this.userInfo.uid}`)

						let rongRes = await RongIMLib.connect(this.userInfo.rongcloudToken)
						if (rongRes.code === RongIMLib.ErrorCode.SUCCESS) {
							console.log('链接成功, 链接用户 id 为: ', rongRes.data.userId);
						} else {
							console.warn('链接失败, code:', rongRes.code)
						}
					}

					this.isRefresh = true
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 获取当前用户余额
		async _getBalance() {
			try {
				let res = await getBalance()
				if (res.data) {
					this.balance = {
						diamond: res.data.diamond || 0,
						coin: res.data.coin || 0
					}
					// 更新用户余额信息
					this.setBalance(this.balance);
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 获取特权列表
		async _getLeveList() {
			try {
				let res = await getLeveList()
				if (res.data && res.data.list) {
					let levelList = res.data.list
					let index = levelList.findIndex(item => item.id == this.userInfo.levelId)

					// 最高等级
					if (index == levelList.length - 1) {
						this.userLeveInfo = {
							leftName: levelList[index - 1].name,
							leftExp: levelList[index - 1].exp,
							rightName: levelList[index].name,
							rightExp: levelList[index].exp,
							needExp: 0,
							progress: '100%',
						}
						return
					}
					// 如果index=-1没找到，及他当前等级为：1萌新 || 2新星 || 3平民
					let _index = index + 1
					let nowExp = 0
					// 非这3个等级（1萌新、2新星、3平民）当前经验值取当前等级
					if (this.userInfo.levelId > 3) {
						nowExp = levelList[index].exp
					}
					let leftName = this.userInfo.levelId == 1 || this.userInfo.levelId == 2 || this.userInfo.levelId == 3 ? '平民' : this.userInfo.levelName
					// 两个等级相差总经验值
					let expAllDifference = levelList[_index].exp - nowExp
					// 用户当前经验值-当前等级经验值=超出当前等级经验值
					let expDifference = this.userInfo.experience - nowExp
					// 等级升级进度条
					let progress = expDifference * 100 / expAllDifference < 100 ? expDifference * 100 / expAllDifference + '%' : '100%'
					this.userLeveInfo = {
						leftName: leftName,
						leftExp: nowExp,
						rightName: levelList[_index].name,
						rightExp: levelList[_index].exp,
						needExp: expAllDifference - expDifference,
						progress: progress,
					}
				}
			} catch (error) {
				console.log(error)
			}
		},
		...mapActions(["setUserInfo", "setToken", "setShowLogin", "setShowBindPhone", "setShowAuth", "setBalance", "setQuickRoomList", "setMessageUnreadCount", "setKeywords", "addWorldRoomMessage"]),
		loginFun() {
			this.setShowLogin(true)
		},
		// 关闭登录弹窗
		closeLogin(value) {
			// console.log(value)
			this.setShowLogin(value)
		},
		// 关闭绑定手机号弹窗
		closeBindPhone(value) {
			// console.log(value)
			this.setShowBindPhone(value)
		},

		// 关闭实名认证弹窗
		closeAuthHandle(value) {
			console.log(value)
			this.isShowAuth = false
			this.setShowAuth(value)
		},

		async getUserinfo(value) {
			this.isLogin = value;
			let storeInfo = JSON.parse(localStorage.getItem("storeInfo"))
			this.userInfo = storeInfo.userInfo
			window.location.reload()
		},

		login() {
			// 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
			this.setShowLogin(true);
		},
		// 退出登录
		logout() {
			this.visible = false;
			this.isLogin = false
			// 清空vuex登录信息
			this.setUserInfo("");
			this.setToken("");
			this.setShowLogin(true);

			if (Events) {
				// 移除具体事件的所有监听器
				RongIMLib.removeEventListeners(Events.MESSAGES)
			}
		},
		// 接收注册子组件传过来的数据
		isRegister(val) {
			this.register = val;
		},
		// 点击搜索按钮
		searchClick() {
			if (this.search != "") {
				// 跳转到搜索页面,并传递搜索条件
				let routeData = this.$router.resolve({ path: '/search', query: { value: this.search } });
				window.open(routeData.href, '_blank');
			}
		},
		// 跳转消息
		goMessage() {
			let routeData = this.$router.resolve(`/setup/${0}/${0}`);
			window.open(routeData.href, '_blank');
		},

		// 获取快速进房列表
		async _getQuickRoomList() {
			try {
				let res = await getQuickRoomList()
				if (res.data && res.data.list.length >= 0) {
					this.quickRoomList = res.data.list
					this.setQuickRoomList(deepClone(res.data.list))
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取房间分类对应的房间列表
		async _getRoomTypeList(id) {
			try {
				let params = {
					type: id,
					pageNum: 1,
					pageSize: 50
				}
				let res = await getRoomTypeList(params)
				if (res.data && res.data.list.length >= 0) {
					return res.data.list
				}
			} catch (error) {
				console.log(error)
				return []
			}
		},

		// 跳转用户协议、隐私政策、关于我们
		agreeHandle(type) {
			let routeData = this.$router.resolve(`/${type}`);
			window.open(routeData.href, '_blank');
		},

		// 关闭萌新弹窗
		closeMengXin() {
			this.isShowMengXin = false
		}
	},
	destroyed() {
		this.setShowBindPhone(false)
	}
};
</script>
<style lang="less">
* {
	padding: 0;
	margin: 0;
	border: 0;
	list-style: none;
	/* user-select: none; */
}

body {
	background: #201A25;
	font-family: PingFang SC, 'Microsoft YaHei', "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana, sans-serif;
}

.el-main {
	padding: 0 !important;
	min-height: 2500px !important;
	height: 2500px !important;
}

.el-message-box {
	background: #3F314A !important;
	border: none !important;

	.el-message-box__title,
	.el-message-box__content {
		color: #FFFFFF !important;
	}

}

@media (max-width:1436px) {

	/* body{
			overflow-x:hidden;
		} */
	.topbar {
		width: 100% !important;
	}
}

/* 音频动画 */
.voice-playing {
	height: 12px;
	width: 10px;
	float: left;
	display: flex;
	/* 底部对齐，实现从下往上的动画效果 */
	align-items: flex-end;
	justify-content: space-between;
	margin-top: 6px;

	.play1 {
		width: 2px;
		background-color: #998FA6;
		animation: playing1 .5s linear infinite alternate;
	}

	.play2 {
		width: 2px;
		background-color: #998FA6;
		animation: playing2 .5s linear infinite alternate;
	}

	.play3 {
		width: 2px;
		height: 12px;
		background-color: #998FA6;
		animation: playing3 .5s .2s linear infinite alternate;
	}
}

@keyframes playing1 {
	0% {
		height: 4px;
	}

	100% {
		height: 12px;
	}
}

@keyframes playing2 {
	0% {
		height: 12px;
	}

	100% {
		height: 8px;
	}
}

@keyframes playing3 {
	0% {
		height: 7px;
	}

	100% {
		height: 12px;
	}
}

@import "./common/style.less";

/* 冠名样式 */
.naming_day {
	color: #C5A8F7;
}

.icon_naming_day {
	height: 15px;
	width: 16px;
	display: inline-block;
	background: url('./assets/imgs/icon_naming_day.png')no-repeat center/cover;
}

.naming_month {
	color: #FFAF90;
}

.icon_naming_month {
	height: 15px;
	width: 16px;
	display: inline-block;
	background: url('./assets/imgs/icon_naming_month.gif')no-repeat center/cover;
}

.naming_year {
	color: #F7E6A8;
}

.icon_naming_year {
	height: 15px;
	width: 16px;
	display: inline-block;
	background: url('./assets/imgs/icon_naming_year.gif')no-repeat center/cover;
}
</style>
<style lang="less" scoped>
#app {
	.el-header {
		padding: 0;
		height: 80px !important;
	}

	.el-main {
		min-height: 300px;
		padding: 20px 0;
	}
}

a,
a:hover {
	text-decoration: none;
}

.bgcolor {
	background-color: #201A25;
	// height: 80px;
	min-height: 100vh;
}

.topbar {
	width: 90%;
	height: 80px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;

	.el-header {
		height: 80px;
		display: flex;
		justify-content: space-between;

		.logo {
			width: 163px;
			height: 50px;
			margin: 15px 48px 0 15px;
			cursor: pointer;
		}

		.el-menu {
			background: none;
			border: none;
			height: 80px;

			.el-menu-item {
				width: 96px;
				height: 78px;
				line-height: 78px;
				text-align: center;
				font-size: 20px;
				color: #fff;

				&:hover,
				&:focus {
					border-bottom: 2px solid #B586F2 !important;
					color: #B586F2;
					background: linear-gradient(#201a25, #6e5291);
				}
			}

			.is-active {
				border-bottom: 2px solid #B586F2;
				color: #B586F2;
				background: linear-gradient(#201a25, #6e5291);
			}
		}

		.searchCss {
			margin-top: 24px;
			margin-left: 30px;
			font-size: 14px;

			.el-input {
				color: red;

				::v-deep input {
					width: 200px;
					height: 32px;
					background: rgba(255, 255, 255, 0.05);
					border-radius: 16px;
					border: 1px solid rgba(255, 255, 255, 0.1);
					color: #fff;
				}

				::v-deep .el-input__icon {
					line-height: 32px;
				}
			}
		}
	}

	.nav {
		margin-left: 50px;

		ul {
			// width: 210px;
			display: flex;
			justify-content: space-between;

			li {
				min-width: 70px;
				text-align: center;
				color: #fff;
				cursor: pointer;
				height: 80px;
				margin-right: 10px;

				&:hover {
					.navli1 {
						background: url(assets/imgs/nav11.png) no-repeat;
						background-size: cover;
					}

					.navli2 {
						background: url(assets/imgs/nav22.png) no-repeat;
						background-size: cover;
					}

					.navlixx {
						background: url(assets/imgs/xx2.png) no-repeat;
						background-size: cover;
					}

					.navli3 {
						background: url(assets/imgs/nav33.png) no-repeat;
						background-size: cover;
					}

					p {
						color: #B586F2;
					}
				}

				.navli {
					width: 36px;
					height: 36px;
					margin: 10px auto 4px;
				}

				.navli1 {
					background: url(assets/imgs/nav1.png) no-repeat;
					background-size: cover;
				}

				.navli2 {
					background: url(assets/imgs/nav2.png) no-repeat;
					background-size: cover;
				}

				.navlixx {
					background: url(assets/imgs/xx.png) no-repeat;
					background-size: cover;
				}

				.navli3 {
					background: url(assets/imgs/nav3.png) no-repeat;
					background-size: cover;
				}

				.userBox {
					overflow: hidden;
					height: 80px;
					width: 200px;

					.userimg {
						height: 40px;
						width: 40px;
						float: left;
						border-radius: 20px;
						margin: 20px 0px 0 0;
						object-fit: cover;
					}

					.userinfo {
						float: left;
						overflow: hidden;
						margin: 20px 0 0 14px;
						width: 140px;

						.userT {
							overflow: hidden;
							width: 100%;
							display: flex;
							align-items: center;

							p {
								font-size: 14px;
								color: #fff;
								float: left;
								max-width: 100px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								text-align: left;
							}

							.grade {
								width: 32px;
								height: 16px;
								line-height: 18px;
								float: left;
								margin-left: 8px;

								img {
									height: 100%;
									width: 100%;
									margin: 0;
								}
							}
						}

						.userB {
							overflow: hidden;
							margin-top: 2px;

							p {
								float: left;
								font-size: 12px;
							}

							i {
								float: left;
								margin-left: 12px;
							}
						}
					}
				}

				img {
					display: block;
					width: 36px;
					margin: 10px auto 4px;
				}

				p {
					font-size: 14px;
				}

				&.lipr {
					position: relative;

					&:hover {
						.userCom {
							display: block;
						}
					}
				}

				&.roompr {
					position: relative;

					&:hover {
						.roombox {
							display: block;
						}

					}
				}

				&.privateBox {
					position: relative;

					.count {
						position: absolute;
						top: 5px;
						right: 10px;
						min-width: 18px;
						height: 18px;
						line-height: 18px;
						text-align: center;
						background: #F56250;
						border-radius: 9px;
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #FFFFFF;
					}
				}
			}
		}
	}
}

.topBar {
	width: 100% !important;

	.el-menu-item {
		width: 0px !important;
		height: 0px !important;
		font-size: 0 !important;
		border: none !important;
	}
}

.el-footer {
	padding: 0;
	height: 210px !important;

	.footer {
		width: 100%;
		height: 210px !important;
		padding: 0;
		color: #666;
		background: #000000;
		text-align: center;

		.webinfo {
			display: inline-block;
			overflow: hidden;
			margin: 32px 0 8px 0;

			li {
				float: left;
				overflow: hidden;
				height: 24px;
				line-height: 24px;
				border-right: 1px solid #666;

				&:last-child {
					border: none;
				}

				img {
					width: 24px;
					float: left;
				}

				p {
					float: left;
					margin: 0 32px 0 8px;
					font-size: 16px;

					span {
						// margin-right: 48px;
						font-size: 14px;
					}
				}

				span {
					font-size: 14px;
					float: left;
					display: block;
					min-width: 110px;
					cursor: pointer;

					a {
						color: #666;
					}
				}
			}
		}

		.comInfo {
			overflow: hidden;
			display: inline-block;
			font-size: 12px;
			margin-top: 8px;

			span {
				float: left;
				margin-right: 16px;
			}

			img {
				float: left;
				margin-right: 6px;
			}

			a {
				float: left;
				margin-right: 16px;
				color: #666;
			}
		}

		.copyright {
			font-size: 12px;
			margin-top: 28px;
		}
	}
}
</style>
