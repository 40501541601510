<template>
    <div class="emojiCom" @contextmenu.prevent @click.stop="isShowDelEmoji = false">
        <div class="box">
            <div class="emojiListBox overflow-auto">
                <div class="emojiList d-flex a-center flex-wrap">
                    <div class="emojiItem" :style="{ marginRight: (index + 1) % 5 == 0 ? '0' : '26px' }"
                        v-for="(item, index) in emojiList" :key="item.id" @click="emojiHandle(item, index)"
                        @contextmenu.prevent @click.right="emojiRightHandle(item, index)">
                        <img class="emojiIcon" :src="item.value" alt="">
                        <div class="delEmoji" v-if="emojiRightHandleInfo.index == index && isShowDelEmoji"
                            @click.stop="delEmojiHandle(emojiRightHandleInfo.id)">删除</div>
                    </div>
                </div>
            </div>
            <div class="emojiTabBox d-flex a-center">
                <div :class="['tab', { tabCheck: index == tabIndex }]" v-for="(item, index) in tabList" :key="item.id"
                    @click="changeTab(index)">
                    <img class="tabIcon" :src="index == tabIndex ? item.checkIcon : item.defualutIcon" alt="">
                </div>
            </div>
        </div>

        <!-- 只用input来实现上传，但是不显示input -->
        <input v-show="false" ref="fileRef" type="file" accept="image/png, image/jpeg, image/jpg, image/gif"
            @change="getImageInfo" />
    </div>
</template>

<script>
import {
    getExpressList,
    addExpress,
    delExpress
} from "@/util/api/api.js"
import bus from '@/util/eventBus.js'
import uploadApi from '@/util/uploadImage'

export default {
    name: 'emojiCom',
    props: {
        from: {
            type: String
        }
    },
    data() {
        return {
            sysEmojiList: [], // 系统表情包
            collectList: [], // 收藏表情包
            emojiList: [],// 列表渲染表情包
            tabList: [
                {
                    id: 1,
                    type: 'sys',
                    defualutIcon: require('../assets/imgs/emoji/icon_emoji.png'),
                    checkIcon: require('../assets/imgs/emoji/icon_emoji_check.png'),
                },
                {
                    id: 2,
                    type: 'collect',
                    defualutIcon: require('../assets/imgs/emoji/icon_emojiCollect.png'),
                    checkIcon: require('../assets/imgs/emoji/icon_emojiCollect_check.png'),
                },
            ],// 表情包tab分类
            tabIndex: 0,// 当前所选tab下标
            emojiRightHandleInfo: {},// 表情包右击信息
            isShowDelEmoji: false,// 是否展示表情包删除按钮
        }
    },
    created() {
        this.getExpressList()

        for (let i = 0; i <= 16; i++) {
            this.sysEmojiList.push({
                id: Symbol(),
                type: 'sys',
                key: 's1_' + i,
                value: require(`../assets/imgs/emoji/s1_${i}.png`)
            },)
        }

        this.emojiList = JSON.parse(JSON.stringify(this.sysEmojiList))
    },
    methods: {
        // 切换表情分类
        changeTab(index) {
            this.tabIndex = index
            if (this.tabList[index].type == 'sys') {
                this.emojiList = JSON.parse(JSON.stringify(this.sysEmojiList))
            } else if (this.tabList[index].type == 'collect') {
                this.emojiList = JSON.parse(JSON.stringify(this.collectList))
                this.emojiList.unshift({
                    id: Symbol(),
                    type: 'collect',
                    key: 'addEmoji',
                    value: require(`../assets/imgs/emoji/icon_addEmoji.png`)
                })
            }
        },

        // 获取自定义表情包列表
        async getExpressList() {
            try {
                let res = await getExpressList()
                this.collectList = []
                if (res.resultCode == '000000' && res.data.list.length > 0) {
                    res.data.list.forEach(item => {
                        this.collectList.push({
                            id: item.id,
                            type: 'collect',
                            key: item.id,
                            value: item.imgUrl
                        })
                    })

                    if (this.tabList[this.tabIndex].type == 'collect') {
                        this.emojiList = JSON.parse(JSON.stringify(this.collectList))
                        this.emojiList.unshift({
                            id: Symbol(),
                            type: 'collect',
                            key: 'addEmoji',
                            value: require(`../assets/imgs/emoji/icon_addEmoji.png`)
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },

        // 表情事件
        emojiHandle(item, index) {
            if (this.tabList[this.tabIndex].type == 'collect' && index == 0) {
                console.log('上传自定义表情')
                this.$refs.fileRef.click()
            } else {
                let emojiInfo = {
                    action: 'im', // enter进入房间, im群聊信息, gift礼物, agree同意申请上麦
                    type: this.tabList[this.tabIndex].type == 'sys' ? 3 : 4, // im群聊信息：1、文本 2、图片 3、表情—系统 4、表情—收藏；  enter进入房间：1、进入房间
                    value: '',
                    msgUrl: this.tabList[this.tabIndex].type == 'sys' ? item.key : item.value// 表情包——字段、图片
                }

                // 系统表情 && 筛子
                if (this.tabList[this.tabIndex].type == 'sys' && item.key == 's1_15') {
                    // 随机生成一个筛子数值
                    let i = Math.round(Math.random() * 5 + 1);
                    emojiInfo.msgUrl = 'dice_' + i
                }

                // 系统表情 && 猜拳
                if (this.tabList[this.tabIndex].type == 'sys' && item.key == 's1_16') {
                    // 随机生成一个猜拳数值
                    let i = Math.round(Math.random() * 2 + 1);
                    emojiInfo.msgUrl = 'scissors_' + i
                }

                // 直播间回调
                if (this.from == 'room') {
                    bus.$emit('watchEmojiSend', { emojiInfo })
                    this.$emit('sendSuccessHandle')
                } else if (this.from == 'privateChat') {
                    // 私聊回调
                    this.$emit('sendSuccessHandle', { emojiInfo })
                } else {
                    this.$emit('sendSuccessHandle', { emojiInfo })
                }
            }
        },

        // 选择图片文件并上传
        async getImageInfo(e) {
            // 上传的文件
            let file = e.target.files[0]
            console.log(file)
            const maxSize = 1
            let fileSize = file.size / 1024 / 1024

            if (fileSize > maxSize) {
                this.$message.warning(`图片大小必须在${maxSize}MB以内！`)
                return false
            }

            var loading = this.$loading({
                lock: true,
                text: '表情添加中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            try {
                let res = await uploadApi(file, maxSize)
                console.log(res)
                if (res.picture == 'success') {
                    console.log('上传成功')
                    await this.addEmoji(res.url)
                    loading.close()
                } else {
                    this.$message.error(res.msg);
                    loading.close()
                }
            } catch (error) {
                console.log(error)
                loading.close()
            }
        },

        // 添加自定义表情
        async addEmoji(url) {
            try {
                let params = {
                    imgUrl: url
                }
                let res = await addExpress(params)
                if (res.resultCode == '000000') {
                    this.$message.success("添加成功")
                    await this.getExpressList()
                }
            } catch (error) {
                console.log(error)
            }
        },

        // 右击表情事件
        emojiRightHandle(item, index) {
            // 非收藏表情不做任何处理
            if (this.tabList[this.tabIndex].type != 'collect' || index == 0) {
                return
            }
            this.emojiRightHandleInfo = {
                index: index,
                id: item.id
            }
            this.isShowDelEmoji = true
        },

        // 删除自定义表情
        async delEmojiHandle(id) {
            try {
                let params = {
                    id: id
                }
                let res = await delExpress(params)
                if (res.resultCode == '000000') {
                    this.$message.success("删除成功")
                    this.isShowDelEmoji = false
                    await this.getExpressList()
                }
            } catch (error) {
                console.log(error)
            }
        },
    },

    beforeDestroy() {
        bus.$off('watchPrivateChatUpdate')
    }
}
</script>

<style lang="less" scoped>
.emojiCom {
    .box {
        width: 375px;
        background: #201A25;
        border: 1px solid #463B50;

        .emojiListBox {
            height: 260px;
            overflow-y: auto;

            .emojiList {
                padding: 12px 0 12px 15px;

                .emojiItem {
                    position: relative;
                    margin-right: 26px;
                    margin-bottom: 20px;
                    cursor: pointer;

                    .emojiIcon {
                        border-radius: 6px;
                        display: block;
                        height: 48px;
                        width: 48px;
                        object-fit: contain;
                    }

                    .delEmoji {
                        position: absolute;
                        left: 29px;
                        top: 8px;
                        width: 57px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        background: #19141D;
                        border: 1px solid rgba(255, 255, 255, 0.2);
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        z-index: 1;
                        cursor: pointer;

                        &:hover {
                            background: #3F314A;
                        }
                    }
                }
            }
        }

        .emojiTabBox {
            width: 375px;
            height: 48px;
            background: #201A25;
            opacity: 0.9;
            padding: 0 16px;
            border-top: 1px solid #463B50;
            box-sizing: border-box;
            overflow-x: auto;

            .tab {
                width: 32px;
                height: 32px;
                border-radius: 8px;
                margin-right: 20px;
                cursor: pointer;

                &.tabCheck {
                    background: rgba(255, 255, 255, 0.1);
                }

                .tabIcon {
                    display: block;
                    height: 32px;
                    width: 32px;
                }
            }
        }
    }

}
</style>
