<template>
	<div class="privateChatCover" v-if="isPrivateChat">
		<div class="flex-s-b privateChat">
			<div class="privareL">
				<div class="searchCss">
					<el-input placeholder="搜索昵称" prefix-icon="el-icon-search" v-model="searchValue"
						@input="searchInputHandle">
					</el-input>
				</div>
				<!-- 展示过滤后的会话列表 -->
				<ul class="overflow-auto userlist" v-if="isShowFilterUserList">
					<li :class="['flex-s-b']" v-for="(item) in filterUserList" :key="item.targetId"
						@click="userClick(item)">
						<div class="userImgBox">
							<img class="userimg" :src="item.imgUrl" />
							<span class="count" v-if="item.unreadMessageCount > 0">{{ item.unreadMessageCount }}</span>
						</div>
						<div class="flex-s-b user-info">
							<p class="flex-f-c username">
								<img class="levelIcon" v-if="item.levelImg" :src="item.levelImg" />
								<span class="text">{{ item.nickname }}</span>
							</p>
							<div class="chat-time">
								{{ item.date }}
							</div>
							<p class="chat-txt">
								{{ item.valueText }}
							</p>
						</div>
					</li>
				</ul>

				<ul class="overflow-auto userlist" v-else>
					<li :class="['flex-s-b', { usersel: usersel == index }]" v-for="(item, index) in userList"
						:key="item.targetId" @click="userClick(item)">
						<div class="userImgBox">
							<img class="userimg" :src="item.imgUrl" />
							<span class="count" v-if="item.unreadMessageCount > 0">{{ item.unreadMessageCount }}</span>
						</div>
						<div class="flex-s-b user-info">
							<p class="flex-f-c username">
								<img class="levelIcon" v-if="item.levelImg" :src="item.levelImg" />
								<span class="text">{{ item.nickname }}</span>
							</p>
							<div class="chat-time">
								{{ item.date }}
							</div>
							<p class="chat-txt">
								{{ item.valueText }}
							</p>
						</div>
					</li>
				</ul>
			</div>
			<div class="privareR">
				<div class="flex-s-b h64">
					<p class="flex-f-c username">
						<img class="levelIcon" v-if="userList[usersel] && userList[usersel].levelImg"
							:src="userList[usersel].levelImg" />
						<span class="text d-overflow" v-if="userList[usersel] && userList[usersel].nickname">{{
							userList[usersel].nickname
						}}</span>
					</p>
					<i class="el-icon-close closebtn" @click="closeHandle"></i>
				</div>
				<!-- 聊天记录 -->
				<div class="overflow-auto chatLog" ref="chatScroll">
					<div class="titleDesc">一切以<span>投资、理财、炒币、炒股</span>等赚钱理由，要求加微信或下载其他软件，均为诈骗，请立即举报</div>
					<div class="roomBox d-flex a-center j-center" v-if="userRoom && userRoom.online" @click="goRoomHandle">
						<div class="voice-playing">
							<div class="play1"></div>
							<div class="play2"></div>
							<div class="play3"></div>
						</div>
						<div class="text">我正在【{{ userRoom.name }}】，快来找我一起玩</div>
					</div>
					<div class="chatItem" v-for="(item, index) in chatList" :key="index">
						<div class="chatTime" v-if="item.isShowTime">
							{{ item.date }}
						</div>
						<div :class="['otherRecords', { myRecords: item.isMy }]">
							<img class="userimg" :src="item.isMy ? rightImgUrl : leftImgUrl" @click="goUserHome(item)" />
							<div :class="['messageBox', { imgMessageBox: item.type != 1 }]">
								<div class="imBox" v-if="item.action == 'im'">
									<div class="text" v-if="item.type == 1">{{ item.value }}</div>
									<el-image class="imImg" v-if="item.type == 2" :src="item.msgUrl"
										:preview-src-list="[item.msgUrl]" @load="imgIsLoaded">
									</el-image>
									<img v-if="item.type == 3" class="emoPackImg emoPackImgSys"
										:src="`${localEmojiList[item.msgUrl]}?${item.time}`" @contextmenu.prevent />
									<div class="emojiPackBox" v-if="item.type == 4">
										<el-image class="emoPackImg" :src="item.msgUrl" @contextmenu.prevent
											:preview-src-list="[item.msgUrl]" @load="imgIsLoaded"
											@click.right="e => emojiRightHandle(e, index, item)">
										</el-image>
										<div class="addEmoji" v-if="emojiRightHandleInfo.index == index && isShowAddEmoji"
											@click="addEmojiHandle">添加表情</div>
									</div>
								</div>
								<div class="gift d-flex a-center" v-else-if="item.action == 'gift'">
									<span class="text">{{ item.isMy ? '你送给对方' : '对方送给你' }} {{ item.gnum }}个</span>
									<img class="giftIcon" :src="item.gurl" alt="">
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="flex-s-b chatL privareSend">
					<div class="gift giveGiftBtn" @click="openGiftPop()">赠送礼物</div>
					<div class="flex-f-r chatInput a-center j-sb">
						<i class="emojiIcon" @click="openEmojiCom"></i>
						<input placeholder="说点什么..." v-model="messageValue" @keyup.enter="sendMessageHandle(1)" />
						<div class="right d-flex a-center">
							<img class="iconUploadImg" src="../assets/imgs/icon_uploadImg.png" alt="" @click="chooseImage">
							<button @click="sendMessageHandle(1)">发送</button>
						</div>
					</div>
				</div>

				<!-- 只用input来实现上传，但是不显示input -->
				<input v-show="false" ref="fileRef" type="file" accept="image/png, image/jpeg,, image/gif"
					@change="getImageInfo" />
			</div>

			<!-- 礼物modal -->
			<div class="giftModal d-flex flex-column" @click.stop="" v-if="isGiftModal">
				<!-- 礼物选项卡 -->
				<div class="flex-s-b">
					<ul class="d-flex a-center giftType">
						<li :class="{ giftsel: typesel == index }" v-for="(item, index) in giftType" :key="index"
							@click="typeClick(item, index)">{{ item.label }}</li>
					</ul>
					<div class="bagDiamondNum d-flex a-center" v-if="typesel == 1">
						<img class="icon_diamond" src="../assets/imgs/z.png" />
						<span class="text">{{ bagDiamondNum }}</span>
					</div>
				</div>
				<div class="giftcont">
					<div v-if="giftList.length > 0 && !isLoadingGift">
						<!-- 礼物 -->
						<ul class="overflow-auto d-flex flex-wrap" v-if="typesel == 0">
							<li :class="{ sel: giftsel == index }" v-for="(item, index) in giftList" :key="index"
								@click="giftClick(item, index)">
								<div class="w64">
									<img :src="item.iconUrl" />
								</div>
								<p class="giftname">{{ item.name }}</p>
								<p class="num d-flex a-center j-center">
									<img src="../assets/imgs/z.png" />
									{{ item.diamond }}
								</p>
							</li>
						</ul>
						<!-- 背包 -->
						<ul class="overflow-auto d-flex flex-wrap" v-if="typesel == 1">
							<li :class="{ sel: giftsel == index }" v-for="(item, index) in giftList" :key="index"
								@click="giftClick(item, index)">
								<div class="w64">
									<img :src="item.giftIconUrl" />
									<p class="cs" v-if="item.num">{{ item.num }}</p>
								</div>
								<p class="giftname">{{ item.giftName }}</p>
								<p class="num d-flex a-center j-center">
									<img src="../assets/imgs/z.png" />
									{{ item.diamond }}
								</p>
							</li>
						</ul>
					</div>

					<div class="noData d-flex flex-column a-center j-center" v-else-if="!isLoadingGift">
						<img class="iconNoData" src="@/assets/imgs/setup/icon_noData.png" alt="">
						<div class="text">暂无礼物</div>
					</div>

				</div>

				<div class="flex-s-b sgiftBottom">
					<div class="czCont">
						<img src="../assets/imgs/z.png" />
						<span>{{ balance.diamond || 0 }}</span>
						<p @click="goRecharge">去充值<i class="el-icon-arrow-right"></i></p>
					</div>
					<div class="flex-s-b zsCont"
						v-if="!(typesel == 1 && giftList && giftList.length > 0 && giftsel != null && giftList[giftsel].type == 2)">
						<p class="giveNumBox d-flex a-center">
							<input class="num" v-model="giveGiftNum" type="text" @input="giveNumHandle" />
							<i :class="['fastIcon', { 'el-icon-arrow-up': !isShowGiveFastNumPop }, { 'el-icon-arrow-down': isShowGiveFastNumPop }]"
								@click="giveFastNumHandle"></i>
						</p>
						<el-button type="primary" @click="giveHandle" :loading="isLoadingBtn">{{ typesel != 3 ? '赠送' : '抽取'
						}}</el-button>
					</div>
				</div>

				<!-- 礼物快捷数量弹窗 -->
				<div class="giveFastNumBox d-flex a-center j-sb" v-if="isShowGiveFastNumPop">
					<div class="itemBox" v-for="(item, index) in giveNumTabList" :key="item.id">
						<div class="num" v-if="!(item.value == 'all' && typesel != 1)"
							:class="{ 'active': index == giveNumTabSel }" @click="giveNumTabHandle(index)">{{
								item.text }}</div>
					</div>
				</div>
			</div>

			<!-- 冠名礼物弹窗 -->
			<div class="allcover modalcss" v-if="isShowNamingPop">
				<div class="namingPop">
					<i class="el-icon-close closebtn" @click="isShowNamingPop = !isShowNamingPop"></i>
					<div class="title">赠送冠名礼物</div>
					<div class="line"></div>
					<div class="taskcont">
						<el-input class="namingInput" :maxLength="4" placeholder="请输入冠名（4个字内）" v-model="namingValue">
						</el-input>
						<div class="desc">冠名有效期{{ giftList[giftsel].days }}天后自动失效</div>
					</div>
					<div class="btnBox d-flex a-center j-center">
						<div class="btn" @click="giveNamingGift">确定</div>
					</div>

				</div>
			</div>

			<!-- 表情包 -->
			<div class="emojiBox" v-show="isShowEmoji">
				<emojiCom ref="emojiRef" from="privateChat" @sendSuccessHandle="emojiSendSuccessHandle"></emojiCom>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapActions
} from "vuex";
import emojiCom from '@/components/emojiCom.vue'

import {
	getGiftListByType4,
	getBagList,
	giveGift,
	giveBagGift,
	getBalance,
	addExpress,
	getUserRoom,
	mengXinClick
} from "@/util/api/api.js"
import uploadApi from '@/util/uploadImage'

import ImageCompressor from 'image-compressor.js' // 引入图片压缩

import bus from '@/util/eventBus.js'

import * as RongIMLib from '@rongcloud/imlib-next'

import { messageTimeFilter, replaceKeywords } from '@/util/util.js'
const { localEmojiList } = require('@/util/emoji.js')
export default {
	components: {
		emojiCom
	},
	props: {
		isPrivateChat: {
			type: Boolean
		},
		chatUserInfo: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			usersel: 0, //选中用户聊天
			searchValue: '',
			userList: [],// 会话列表
			isShowFilterUserList: false,// 是否展示过滤后的会话列表
			filterUserList: [],// 过滤后的会话列表
			chatList: [],// 当前聊天内容列表
			messageValue: '',// 发送文本信息内容
			msgUrl: '',// 发送图片地址
			givePlayIds: [],// 被赠送的用户id
			isGiftModal: false,// 是否展示礼物弹窗
			giftList: [],//礼物列表
			giftsel: 0,//当前所选礼物列表下标
			giftType: [
				{
					value: 1,
					label: '礼物',
				}, {
					value: 2,
					label: '背包',
				}
			],// 礼物类型
			typesel: 0, // 当前所选礼物类型
			isLoadingGift: true, // 正在加载礼物列表
			isShowGiveFastNumPop: false,// 是否展示快捷数量弹窗
			giveNumTabList: [
				{
					id: Symbol(),
					value: 10,
					text: 10
				},
				{
					id: Symbol(),
					value: 66,
					text: 66
				},
				{
					id: Symbol(),
					value: 188,
					text: 188
				},
				{
					id: Symbol(),
					value: 520,
					text: 520
				},
				{
					id: Symbol(),
					value: 'all',
					text: '全部'
				},
			], // 赠送礼物数量快捷tab列表
			giveNumTabSel: null,// 当前所选礼物数量快捷tab
			giveGiftNum: 1,// 赠送的礼物数量
			balance: {
				diamond: 0,
				coin: 0
			},// 用户余额
			bagDiamondNum: 0,// 背包钻总数
			isShowNamingPop: false,// 冠名礼物弹窗是否展示
			namingValue: '',// 冠名礼物输入的冠名
			leftImgUrl: '',// 好友最新头像
			rightImgUrl: '',// 自己最新头像
			isLoadingBtn: false,// 赠送按钮加载中，默认false
			isShowEmoji: false,// 是否展示表情包
			emojiRightHandleInfo: {},// 表情包右击信息
			isShowAddEmoji: false,// 是否展示表情包添加按钮
			localEmojiList: localEmojiList,// 本地表情包key，value
			userRoom: {},// 当前会话用户房间信息
		}
	},
	watch: {
		"$store.state.userInfo": {
			handler(newVal) {
				console.log('监听数据更新：', newVal);
				this.rightImgUrl = newVal.imgUrl
			}
		},
		chatUserInfo() {
			this.initData()
		}
	},
	created() {
		this.initData()
		this.$nextTick(() => {
			this.rightImgUrl = this.$store.state.userInfo.imgUrl
		})

		document.addEventListener('click', (e) => {
			if (e.target.className != 'emojiPackBox') {
				this.isShowAddEmoji = false
			}
		}, false)

		// 监听私聊信息更新
		bus.$on('watchPrivateChatUpdate', res => this.updatePrivateChat(res))
	},
	mounted() {
		document.addEventListener('click', (e) => {
			// console.log(e.target.className)
			if (e.target.className != 'giveGiftBtn') {
				this.isGiftModal = false;
				this.isLoadingBtn = false
			}

			if (e.target.className != 'emojiIcon') {
				this.isShowEmoji = false;
			}
		}, false)
	},
	methods: {
		...mapActions(["setBalance", "setMessageUnreadCount"]),
		// 初始化
		async initData() {
			await this.getConversationList()
			// 判断是否指定私聊对象
			if (this.chatUserInfo && Object.keys(this.chatUserInfo).length > 0) {
				let index = this.userList.findIndex(item => item.id == this.chatUserInfo.id)
				// 在当前会话列表里则指定位置
				if (index >= 0) {
					this.usersel = index
					// 更新会话列表头像、昵称、等级图标为私聊带过来的数据
					this.$set(this.userList[index], 'imgUrl', this.chatUserInfo.imgUrl)
					this.$set(this.userList[index], 'nickname', this.chatUserInfo.nickname)
					this.$set(this.userList[index], 'levelImg', this.chatUserInfo.levelImg)
					this.$set(this.userList[index], 'uid', this.chatUserInfo.uid)
					this.leftImgUrl = this.chatUserInfo.imgUrl
					console.log(this.userList[index])
					await this.getHistoryMessages(this.userList[this.usersel].targetId)
				} else {
					// 否则在会话列表最顶上插入一条
					this._chatUserInfo = {
						isMengXin: 0,
						...this.chatUserInfo,
						targetId: this.chatUserInfo.targetId + '',
						value: '',// 消息内容
						unreadMessageCount: 0,// 未读数量
						date: messageTimeFilter(1, Date.parse(new Date()))// 聊天日期
					}
					console.log('新聊天对象信息：', this._chatUserInfo)
					this.usersel = 0
					this.chatList = []
					this.userList.unshift(this._chatUserInfo)
					this.leftImgUrl = this.userList[this.usersel].imgUrl
				}
			} else {
				await this.getHistoryMessages(this.userList[this.usersel].targetId)
			}
			await this._getUserRoom(this.userList[this.usersel].uid)
		},

		// 更新私聊信息
		async updatePrivateChat(res) {
			console.log("更新消息信息：", res)
			if (res) {
				let { item, data } = res

				// 聊天文本 过滤关键词
				if (data.action == 'im' && data.type == 1) {
					let _value = replaceKeywords(data.value, this.$store.state.keywords)
					data.value = _value
				}

				// 聊天框左侧用户列表数据维护
				let user = {
					id: item.targetId == data.id ? data.id : data.rid,
					uid: item.targetId == data.id ? data.uid : data.ruid,
					action: data.action,
					type: data.type,
					imgUrl: item.targetId == data.id ? data.head : data.rhead,// 用户头像
					nickname: item.targetId == data.id ? data.name : data.rname,// 用户昵称
					levelImg: item.targetId == data.id ? data.levelImg || '' : data.rlevelImg || '',// 用户等级图标
					t3: item.targetId == data.id ? data.t3 || '' : data.rt3 || '',// 用户气泡url
					color: item.targetId == data.id ? data.color || '' : data.rcolor || '',// 用户气泡文本颜色
					value: data.value,// 消息内容
					msgUrl: data.msgUrl,// 图片内容
					targetId: item.targetId,// 会话对应的id
					date: messageTimeFilter(1, item.sentTime)// 聊天日期
				}
				if (data.action == 'im' && data.type == 2) {
					user.valueText = '[图片]'
				} else if (data.action == 'im' && (data.type == 3 || data.type == 4)) {
					user.valueText = '[表情]'
				} else {
					user.valueText = data.value
				}

				// 判断收到的会话信息是否为当前正在聊天会话
				if (item.targetId == this.userList[this.usersel].targetId) {
					console.log('触发清空当前会话未读数,当前会话ID为：', item.targetId)
					this.userList[this.usersel] = {
						...user,
						unreadMessageCount: this.userList[this.usersel].unreadMessageCount + 1 > 99 ? '99+' : this.userList[this.usersel].unreadMessageCount + 1,// 未读数量
					}
					// 清空当前会话未读数
					await this.clearMessagesUnreadStatus(item.targetId)

					let isShowTime = false
					if (this.chatList.length > 0) {
						isShowTime = item.sentTime > (this.chatList[this.chatList.length - 1].time + 1 * 60 * 1000)
					} else {
						isShowTime = true
					}

					let myselfInfo = this.$store.state.userInfo

					let obj = {
						id: data.id,
						uid: data.uid,
						action: data.action,// 类型：im单聊 gift礼物
						type: data.type, // 消息类别：1、文本 2、图片 3、表情
						imgUrl: data.head,// 用户头像
						value: data.value,// 消息内容
						msgUrl: data.msgUrl,// 图片内容
						isMy: myselfInfo.id == data.id,// 是否为自己发的消息
						time: item.sentTime,
						date: messageTimeFilter(2, item.sentTime),// 发送消息的时间,
						isShowTime,
					}
					// 礼物字段添加
					if (data.action == 'gift') {
						obj.gname = data.gname// 礼物名称
						obj.gnum = data.gnum// 礼物数量
						obj.gurl = data.gurl// 礼物小图标
					}

					this.chatList.push(obj)
					this.$nextTick(() => {
						this.bottomScrollClick()
					})
				} else {
					let _index = this.userList.findIndex(it => it.targetId == item.targetId)
					// 先记录当前会话的targetId
					let _targetId = this.userList[this.usersel].targetId
					// 会话列表存在
					if (_index >= 0) {
						// 则会话未读消息数+1，
						user.unreadMessageCount = this.userList[_index].unreadMessageCount + 1 > 99 ? '99+' : this.userList[_index].unreadMessageCount + 1// 未读数量
						// 删除原本会话所在下标信息，插入最新会话到会话列表第一个
						this.userList.splice(_index, 1)
					} else {// 否则会话未读消息树为1
						user.unreadMessageCount = 1
					}
					// 插入最新会话到会话列表第一个
					this.userList.unshift(user)
					// 查找当前会话在新会话列表下标位置并更新
					this.usersel = this.userList.findIndex(ite => ite.targetId == _targetId)
				}

			}

		},

		// 关闭弹窗
		closeHandle() {
			this.$emit('closePrivateChat')
		},

		// 查看用户主页
		goUserHome(item) {
			console.log(item)
			// 不是我 且 存在uid
			if (!item.isMy && item.uid) {
				let routeData = this.$router.resolve(`/homePage/${item.uid}`);
				window.open(routeData.href, '_blank');
			}

		},

		// 获取会话列表信息
		async getConversationList(targetId) {
			try {
				let params = {
					count: 100,
					startTime: 0,
					order: 0
				}
				console.log(new Date())
				let res = await RongIMLib.getConversationList(params)
				if (res.code === 0) {
					console.log(res.code, res.data)
					if (res.data.length > 0) {
						this.userList = []

						res.data.forEach((item, index) => {
							let msgFields = item.latestMessage.content.msgFields || null
							if (item.conversationType == 1 && msgFields) {
								if (msgFields.action == 'im' && msgFields.type == 1) {
									// 过滤关键词
									let _value = replaceKeywords(msgFields.value, this.$store.state.keywords)
									msgFields.value = _value
								}
								let user = {
									id: item.targetId == msgFields.id ? msgFields.id : msgFields.rid,
									uid: item.targetId == msgFields.id ? msgFields.uid : msgFields.ruid,
									action: msgFields.action,
									type: msgFields.type,
									imgUrl: item.targetId == msgFields.id ? msgFields.head : msgFields.rhead,// 用户头像
									nickname: item.targetId == msgFields.id ? msgFields.name : msgFields.rname,// 用户昵称
									levelImg: item.targetId == msgFields.id ? msgFields.levelImg || '' : msgFields.rlevelImg || '',// 用户等级图标
									t3: item.targetId == msgFields.id ? msgFields.t3 || '' : msgFields.rt3 || '',// 用户气泡url
									color: item.targetId == msgFields.id ? msgFields.color || '' : msgFields.rcolor || '',// 用户气泡文本颜色
									value: msgFields.value,// 消息内容
									msgUrl: msgFields.msgUrl,// 图片内容
									targetId: item.targetId,// 会话对应的id
									unreadMessageCount: item.unreadMessageCount > 99 ? '99+' : item.unreadMessageCount,// 未读数量
									date: messageTimeFilter(1, item.latestMessage.sentTime)// 聊天日期
								}
								// console.log(msgFields.action == 'im' && (msgFields.type == 3 || msgFields.type == 4))
								if (msgFields.action == 'im' && msgFields.type == 2) {
									user.valueText = '[图片]'
								} else if (msgFields.action == 'im' && (msgFields.type == 3 || msgFields.type == 4)) {
									user.valueText = '[表情]'
								} else {
									user.valueText = msgFields.value
								}
								this.userList.push(user)
								// console.log(user.valueText)
							}
							// 用于记录当前聊天会话位置
							if (targetId == item.targetId) {
								this.usersel = index
							}
						})

						console.log(this.userList)

						// 判断是否存在聊天对象且是否在会话列表里
						if (this._chatUserInfo) {
							let _index = this.userList.findIndex(item => item.targetId == this._chatUserInfo.targetId)
							if (_index < 0) {
								this.userList.unshift(this._chatUserInfo)
								this.usersel = 1
							}
						}
						this.leftImgUrl = this.leftImgUrl ? this.leftImgUrl : this.userList && this.userList[this.usersel] ? this.userList[this.usersel].imgUrl : ''
						// console.log(this.userList)
					}
				} else {
					console.log(res.code, res.msg)
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 维护会话列表信息
		updateUserList(user, index) {
			if (user.action == 'im' && user.type == 1) {
				// 过滤关键词
				let _value = replaceKeywords(user.value, this.$store.state.keywords)
				user.value = _value
			}
			if (user.action == 'im' && user.type == 2) {
				user.valueText = '[图片]'
			} else if (user.action == 'im' && (user.type == 3 || user.type == 4)) {
				user.valueText = '[表情]'
			} else {
				user.valueText = user.value
			}

			// 当前会话不在第一条，则调整当前会话为第一条
			if (index > 0) {
				// 先记录当前会话的targetId
				let _targetId = this.userList[this.usersel].targetId
				// 删除原本会话所在下标信息，插入最新会话到会话列表第一个
				this.userList.splice(index, 1)
				// 插入最新会话到会话列表第一个
				this.userList.unshift(user)
				// 查找当前会话在新会话列表下标位置并更新
				this.usersel = this.userList.findIndex(ite => ite.targetId == _targetId)
			} else if (index == 0) {
				this.userList[this.usersel] = user
			}
		},

		// 获取指定targetId聊天历史记录
		async getHistoryMessages(targetId) {
			try {
				const conversation = {
					conversationType: RongIMLib.ConversationType.PRIVATE,
					targetId: targetId
				}
				// 从当前时间开始向前查询
				const option = {
					timestamp: 0,
					count: 20,
					order: 0
				}
				let res = await RongIMLib.getHistoryMessages(conversation, option)
				if (res.code === 0) {
					console.log(res.data.list)
					// 清空当前会话未读数
					this.clearMessagesUnreadStatus(targetId)
					this.chatList = []
					if (res.data.list.length > 0) {
						let myselfInfo = this.$store.state.userInfo
						console.log(myselfInfo)
						res.data.list.forEach((item, index) => {
							let msgFields = item.content.msgFields
							let isShowTime = false
							console.log("当前的消息发送内容：", msgFields.value)
							if (index == 0) {
								isShowTime = true
							} else if (index < res.data.list.length) {
								// console.log("当前的消息发送时间：", item.sentTime - 1 * 60 * 1000)
								// console.log("上一条消息发送时间：", res.data.list[index - 1].sentTime)
								// console.log("当前消息是否展示时间：", (item.sentTime - 1 * 60 * 1000) > res.data.list[index - 1].sentTime)
								isShowTime = (item.sentTime - 1 * 60 * 1000) > res.data.list[index - 1].sentTime
							}
							if (msgFields.action == 'im' && msgFields.type == 1) {
								// 过滤关键词
								let _value = replaceKeywords(msgFields.value, this.$store.state.keywords)
								msgFields.value = _value
							}
							let obj = {
								id: msgFields.id,
								uid: msgFields.uid,
								action: msgFields.action,// 类型：im单聊 gift礼物、背包
								type: msgFields.type, // 消息类别：1、文本 2、图片 3、表情
								imgUrl: msgFields.head,// 用户头像
								value: msgFields.value,// 消息内容
								msgUrl: msgFields.msgUrl,// 图片内容
								isMy: myselfInfo.id == msgFields.id,// 是否为自己发的消息
								time: item.sentTime,// 时间戳
								date: messageTimeFilter(2, item.sentTime),// 发送消息的时间,
								isShowTime: isShowTime,
							}
							// console.log(obj)
							// 礼物字段添加
							if (msgFields.action == 'gift') {
								obj.gname = msgFields.gname// 礼物名称
								obj.gnum = msgFields.gnum// 礼物数量
								obj.gurl = msgFields.gurl// 礼物小图标
							}
							this.$set(this.chatList, index, obj)
						})
						// 聊天对象不存在头像则拉取历史里的头像
						if (!this.leftImgUrl) {
							let filterArr = this.chatList.filter(item => !item.isMy)
							if (filterArr.length > 0) {
								this.leftImgUrl = filterArr[filterArr.length - 1].imgUrl
							}
						}

					}
					this.$nextTick(() => {
						this.bottomScrollClick()
					})

				} else {
					console.log(res.code, res.msg)
					this.chatList = []
				}
			} catch (error) {
				console.log(error)
			}

		},

		// 清空指定会话未读数（多端同步）
		async clearMessagesUnreadStatus(targetId) {
			try {
				const conversationType = RongIMLib.ConversationType.PRIVATE;
				let res = await RongIMLib.clearMessagesUnreadStatus({ conversationType, targetId })
				if (res.code === 0) {
					// 清理成功
					// console.log(res)
					this.$set(this.userList[this.usersel], 'unreadMessageCount', 0)
					// 发送多端同步未读数消息
					RongIMLib.sendSyncReadStatusMessage({ conversationType, targetId }, Date.now()).then(() => { })
					// 拉取最新单聊会话未读数量
					this.getTotalUnreadCount()
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取单聊会话未读数量
		async getTotalUnreadCount() {
			try {
				const includeMuted = false
				const conversationTypes = [RongIMLib.ConversationType.PRIVATE]
				let res = await RongIMLib.getTotalUnreadCount(includeMuted, conversationTypes)
				if (res.code === 0) {
					// console.log(res.code, res.data)
					// 更新私信未读数量
					this.setMessageUnreadCount(res.data);
				} else {
					console.log(res.code, res.msg)
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 切换用户会话
		userClick(item) {
			// console.log("item", item);
			// 查找当前所选会话在会话列表的位置
			let index = this.userList.findIndex(it => it.targetId == item.targetId)
			if (index >= 0) {
				this.usersel = index
				this.leftImgUrl = this.userList[this.usersel].imgUrl
			}
			this.getHistoryMessages(this.userList[this.usersel].targetId)
			this._getUserRoom(this.userList[this.usersel].uid)
			this.isShowFilterUserList = false
		},

		// 图片事件
		chooseImage() {
			// 当input的type属性值为file时，点击方法可以进行选取文件
			this.$refs.fileRef.click()
		},

		// 图片压缩
		compressFile(file) {
			return new Promise((resolve, reject) => {
				new ImageCompressor(file, {
					quality: 0.6, //压缩质量
					checkOrientation: false, //图片翻转，默认为false
					success(result) {
						//  res 转换之后的文件流
						// 压缩成功之后默认是blob文件，那么这里采用原生的文件转换，其实就是新创建一个文件
						let files = new window.File([result], result.name, { type: result.type })
						// 现在得到的就是标准的文件流，可以直接上传服务器  
						// 当然如果服务器默认接受blob文件，那就当上面描述的都是废话可自动删除、或注释掉
						resolve(files);
					},
					error() {
						reject()
					}
				})
			})
		},

		// 选择图片文件并上传
		async getImageInfo(e) {
			// 上传的文件
			let file = e.target.files[0]
			console.log(file)
			const maxSize = 5
			let fileSize = file.size / 1024 / 1024

			let zipFile = await this.compressFile(file)
			console.log('压缩后的图片文件：', zipFile)

			if (fileSize > maxSize) {
				this.$message.warning(`图片大小必须在${maxSize}MB以内！`)
				return false
			}
			let loading = this.$loading({
				lock: true,
				text: '图片发送中...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			try {
				let res = await uploadApi(file, maxSize)
				console.log(res)
				if (res.picture == 'success') {
					console.log('上传成功')
					this.msgUrl = res.url
					await this.sendMessageHandle(2)
					loading.close()
				} else {
					loading.close()
					this.$message.error(res.msg);
				}
			} catch (error) {
				loading.close()
				console.log(error)
			}
		},

		// 发送单聊消息，type消息类型
		async sendMessageHandle(type) {
			// 文本 && 消息内容不为空
			if (type == 1 && (this.messageValue == '' || this.messageValue.length <= 0)) {
				return
			}
			// 图片 && 图片地址
			if (type == 2 && this.msgUrl == '') {
				return
			}
			let myselfInfo = this.$store.state.userInfo
			// 构建要发送的自定义消息
			let params = {
				action: 'im', // im单聊信息, gift礼物
				type: type, // im单聊信息：1、文本 2、图片 3、表情；
				id: myselfInfo.id,
				uid: myselfInfo.uid,
				head: myselfInfo.imgUrl,
				name: myselfInfo.nickname,
				levelImg: myselfInfo.levelImg || '', // 用户等级图片
				t3: myselfInfo.svgaUrlT3 || '', // 气泡url
				color: myselfInfo.textcolour || '', // 气泡字体颜色
				rid: this.userList[this.usersel].id,
				ruid: this.userList[this.usersel].uid,
				rhead: this.userList[this.usersel].imgUrl,
				rname: this.userList[this.usersel].nickname,
				rlevelImg: this.userList[this.usersel].levelImg || '', // 用户等级图片
				rt3: this.userList[this.usersel].t3 || '', // 气泡url
				rcolor: this.userList[this.usersel].color || '', // 气泡字体颜色
				time: Date.parse(new Date()),
			}

			if (type == 1) {
				params.value = this.messageValue
			} else {
				params.msgUrl = this.msgUrl
			}

			let flag = await this.sendRoogCloudHandle(params, this.userList[this.usersel].targetId)

			if (flag) {
				let isShowTime = false
				if (this.chatList.length > 0) {
					isShowTime = flag.sentTime > (this.chatList[this.chatList.length - 1].time + 1 * 60 * 1000)
				} else {
					isShowTime = true
				}
				// 过滤关键字
				if (params.action == 'im' && params.type == 1) {
					let _value = replaceKeywords(params.value, this.$store.state.keywords)
					params.value = _value
				}

				this.chatList.push({
					action: 'im',
					id: myselfInfo.id,
					uid: myselfInfo.uid,
					type: params.type, // 消息类别：1、文本 2、图片 3、表情
					imgUrl: myselfInfo.imgUrl,// 用户头像
					value: params.value,// 消息内容
					msgUrl: params.msgUrl,// 图片内容
					isMy: true,// 是否为自己发的消息
					time: flag.sentTime,// 时间戳
					date: messageTimeFilter(2, flag.sentTime),// 发送消息的时间,
					isShowTime,
				})

				// 消息发送成功后更新会话列表
				let user = {
					...this.userList[this.usersel],
					action: params.action,
					type: params.type,
					value: params.value,// 消息内容
					msgUrl: params.msgUrl,// 图片内容
					date: messageTimeFilter(1, flag.sentTime)// 聊天日期
				}
				this.updateUserList(user, this.usersel)

				this.messageValue = ''
				// 滚动条置底
				this.bottomScrollClick()
			}
		},

		// 发送礼物、背包消息
		async sendGiftHandle(data) {
			let myselfInfo = this.$store.state.userInfo
			// 构建要发送的自定义消息
			let params = {
				action: 'gift', // im单聊信息, gift礼物
				value: '[礼物消息]',
				id: myselfInfo.id,
				uid: myselfInfo.uid,
				head: myselfInfo.imgUrl,
				name: myselfInfo.nickname,
				levelImg: myselfInfo.levelImg || '', // 用户等级图片
				rid: this.userList[this.usersel].id,
				rhead: this.userList[this.usersel].imgUrl,
				rname: this.userList[this.usersel].nickname,
				rlevelImg: this.userList[this.usersel].levelImg || '', // 用户等级图片
				time: Date.parse(new Date()),
				gname: data.gname,// 礼物名称
				gnum: data.gnum,// 礼物数量
				gurl: data.gurl,// 礼物小图标
			}

			let flag = await this.sendRoogCloudHandle(params, this.userList[this.usersel].targetId)

			if (flag) {
				let isShowTime = false
				if (this.chatList.length > 0) {
					isShowTime = flag.sentTime > (this.chatList[this.chatList.length - 1].time + 1 * 60 * 1000)
				} else {
					isShowTime = true
				}

				// 关闭礼物弹窗
				this.isGiftModal = false
				this.chatList.push({
					action: 'gift',
					id: myselfInfo.id,
					uid: myselfInfo.uid,
					imgUrl: myselfInfo.imgUrl,// 用户头像
					value: this.messageValue,// 消息内容
					isMy: true,// 是否为自己发的消息
					time: flag.sentTime,// 时间戳
					date: messageTimeFilter(2, flag.sentTime),// 发送消息的时间,
					isShowTime,
					gname: params.gname,// 礼物名称
					gnum: params.gnum,// 礼物数量
					gurl: params.gurl,// 礼物小图标
				})

				// 消息发送成功后更新会话列表
				let user = {
					...this.userList[this.usersel],
					action: params.action,
					type: params.type,
					value: params.value,// 消息内容
					msgUrl: params.msgUrl,// 图片内容
					date: messageTimeFilter(1, flag.sentTime)// 聊天日期
				}
				this.updateUserList(user, this.usersel)
				// 滚动条置底
				this.bottomScrollClick()
			}
		},

		// 融云-发送消息事件
		async sendRoogCloudHandle(params, targetId) {
			try {
				const message = new this.$IWNormalMsg({
					msgFields: params
				})
				// 发送消息
				let res = await RongIMLib.sendMessage({
					conversationType: RongIMLib.ConversationType.PRIVATE,
					targetId: String(targetId)
				}, message)
				if (res.code === RongIMLib.ErrorCode.SUCCESS) {
					// 消息发送成功，可以根据返回结果中的 messageId 字段将列表中的该消息状态改为发送成功。
					console.log('消息发送成功', res.data)

					// 萌新私聊 && 第一次构建聊天会话，则触发萌新私聊更新状态
					if (this.userList[this.usersel].isMengXin) {
						this._mengXinClick()
					}

					return res.data
				} else {
					console.log('消息发送失败', res.code, res.msg)
					// 被对方拉黑，则提示
					if (res.code == 405) {
						this.$message.warning("您已被对方拉黑")
					}
					return false
				}

			} catch (error) {
				console.log(error)
				return false
			}
		},

		// 推荐老板私聊状态更新
		async _mengXinClick() {
			try {
				let params = {
					activeLiveId: this.userList[this.usersel].activeLiveId
				}
				let res = await mengXinClick(params)
				if (res.resultCode === '000000') {
					this.userList[this.usersel].isMengXin = 0
					console.log('推荐老板私聊状态更新成功')
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 图片加载成功
		imgIsLoaded() {
			this.$nextTick(() => {
				let scrollEl = this.$refs.chatScroll;
				if (scrollEl) {
					scrollEl.scrollTo({ top: scrollEl.scrollHeight });
				}
			});
		},

		// 聊天框滚动到底部
		bottomScrollClick() {
			this.$nextTick(() => {
				let scrollEl = this.$refs.chatScroll;
				if (scrollEl) {
					console.log('当前滚动条信息：', scrollEl.scrollHeight)
					scrollEl.scrollTo({ top: scrollEl.scrollHeight });
				}
			});
		},

		// 搜索昵称事件
		searchInputHandle(val) {
			this.$nextTick(() => {
				this.filterUserList = []
				this.userList.forEach(item => {
					if (item.nickname.indexOf(val) >= 0) {
						this.filterUserList.push(item)
					}
				})
			})
			this.isShowFilterUserList = true
		},


		// 获取当前用户余额
		async _getBalance() {
			try {
				let res = await getBalance()
				if (res.data) {
					this.balance = {
						diamond: res.data.diamond || 0,
						coin: res.data.coin || 0
					}
					// 更新用户余额信息
					this.setBalance(this.balance);
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 跳转充值
		goRecharge() {
			let routeData = this.$router.resolve(`/recharge`);
			window.open(routeData.href, '_blank');
		},

		// 打开赠送礼物列表弹窗
		async openGiftPop() {
			// 如果已经打开则关闭弹窗
			if (this.isGiftModal) {
				this.isGiftModal = !this.isGiftModal
				return
			}
			this.givePlayIds = []
			this.givePlayIds.push(this.userList[this.usersel].id)

			// 获取用户当前余额
			await this._getBalance()
			this.typesel = 0
			this.giftsel = 0
			this.giveGiftNum = 1
			this.giveNumTabSel = null
			this.isLoadingGift = true
			await this._getGiftListByType4()
			this.isGiftModal = !this.isGiftModal
		},

		// 礼物tab切换
		typeClick(item, index) {
			console.log(this.typesel, index, this.giftList.length)
			if (this.typesel == index && this.giftList.length >= 0) {
				return
			}
			this.isLoadingGift = true
			this.typesel = index
			this.giftsel = 0
			this.giveGiftNum = 1
			this.giveNumTabSel = null
			this.giftList = []
			this.bagDiamondNum = 0
			if (index == 0) {
				this._getGiftListByType4()
			} else if (index == 1) {
				this._getBagList()
			}
		},

		// 礼物选择
		giftClick(item, index) {
			this.giftsel = index
			this.giveGiftNum = 1
			this.giveNumTabSel = null
			// 类型为二级宝箱则打开弹窗
			if (this.typesel == 1 && item.type == 2) {
				this.isShowSecondLevelBox = true
				this.secondLevelBoxInfo = item
				return
			}
		},

		// 礼物数量输入事件
		giveNumHandle(e) {
			let { value } = e.target
			value = value.replace(/\D/g, '')
			if (value < 1) {
				value = 1
			}
			this.giveNumTabSel = null
			this.giveGiftNum = value
		},

		// 打开、关闭礼物快捷数量弹窗
		giveFastNumHandle() {
			this.isShowGiveFastNumPop = !this.isShowGiveFastNumPop
		},

		// 礼物快捷数量点击事件
		giveNumTabHandle(index) {
			this.giveNumTabSel = index
			// 背包 && 选择全部
			if (this.typesel == 1 && this.giveNumTabList[index].value == 'all') {
				if (this.giftList[this.giftsel].num > 520) {
					this.giveGiftNum = 520
					this.$message.warning('最大赠送数量为520')
				} else {
					this.giveGiftNum = this.giftList[this.giftsel].num
				}
			} else {
				this.giveGiftNum = this.giveNumTabList[index].value
			}
			this.isShowGiveFastNumPop = false
		},

		// 获取直送礼物列表
		async _getGiftListByType4() {
			try {
				let res = await getGiftListByType4()
				if (res.resultCode === '000000' && res.data) {
					this.giftList = res.data.list || []
					this.isLoadingGift = false
				}
			} catch (error) {
				console.log(error)
				this.isLoadingGift = false
			}
		},
		// 获取我的背包礼物列表
		async _getBagList() {
			try {
				let res = await getBagList({ type: 1 })
				if (res.resultCode === '000000' && res.data) {
					this.giftList = res.data.list || []
					if (this.giftList.length > 0) {
						let bagDiamondNum = 0
						this.giftList.forEach(item => {
							// 背包礼物总价值（去除二级宝箱）
							if (item.type != 2) {
								bagDiamondNum += item.diamond * item.num
							}
						})
						this.bagDiamondNum = bagDiamondNum

						// 背包默认选中第一个非二级宝箱的礼物
						let _index = this.giftList.findIndex(item => item.type != 2)
						this.giftsel = _index >= 0 ? _index : null
					}
					this.isLoadingGift = false
				}
			} catch (error) {
				console.log(error)
				this.isLoadingGift = false
			}
		},

		// 发送礼物
		async giveHandle() {
			this.isLoadingBtn = true
			if (this.typesel == 0) {
				if (this.givePlayIds.length <= 0) {
					this.$message.warning("请选择被赠送者")
					this.isLoadingBtn = false
					return
				}
				// 冠名礼物
				if (this.giftList[this.giftsel].naming) {
					this.isShowNamingPop = true
					this.isLoadingBtn = false
					return
				}
				// 直送
				let params = {
					id: this.giftList[this.giftsel].id,
					num: this.giveGiftNum,
					targetLiveUserIds: this.givePlayIds.join(','),
					text: '',
				}
				let flag = await this._giveGift(params)
				flag && this.sendGiftHandle({
					gname: this.giftList[this.giftsel].name,
					gnum: this.giveGiftNum,
					gurl: this.giftList[this.giftsel].iconUrl,
				})
			} else if (this.typesel == 1) {
				if (this.givePlayIds.length <= 0) {
					this.$message.warning("请选择被赠送者")
					this.isLoadingBtn = false
					return
				}
				// 冠名礼物
				if (this.giftList[this.giftsel].naming) {
					this.isShowNamingPop = true
					this.isLoadingBtn = false
					return
				}
				// 背包
				let params = {
					id: this.giftList[this.giftsel].giftId,
					num: this.giveGiftNum,
					targetLiveUserIds: this.givePlayIds.join(','),
				}
				let flag = await this._giveBagGift(params)
				flag && this.sendGiftHandle({
					gname: this.giftList[this.giftsel].giftName,
					gnum: this.giveGiftNum,
					gurl: this.giftList[this.giftsel].giftIconUrl,
				})
			}
			this.giveGiftNum = 1
			this.giveNumTabSel = null
			this.isLoadingBtn = false
			await this._getBalance()
		},

		// 发送冠名礼物
		async giveNamingGift() {
			if (!this.namingValue) {
				this.$message.warning("冠名还未输入")
				return
			}
			if (this.typesel == 0) {
				// 直送
				let params = {
					id: this.giftList[this.giftsel].id,
					num: this.giveGiftNum,
					targetLiveUserIds: this.givePlayIds.join(','),
					text: this.namingValue,
				}
				let flag = await this._giveGift(params)
				flag && this.sendGiftHandle({
					gname: this.giftList[this.giftsel].name,
					gnum: this.giveGiftNum,
					gurl: this.giftList[this.giftsel].iconUrl,
				})
			} else if (this.typesel == 1) {
				// 背包
				let params = {
					id: this.giftList[this.giftsel].giftId,
					num: this.giveGiftNum,
					targetLiveUserIds: this.givePlayIds.join(','),
					text: this.namingValue,
				}
				let flag = await this._giveBagGift(params)
				flag && this.sendGiftHandle({
					gname: this.giftList[this.giftsel].giftName,
					gnum: this.giveGiftNum,
					gurl: this.giftList[this.giftsel].giftIconUrl,
				})
			}
			this.namingValue = ''
			this.isShowNamingPop = false
		},

		// 冠名输入事件
		namingInputHandle(val) {
			console.log(val)
			val = val.replace(/^\s+|\s+$/g, "");
			if (val.length >= 4) {
				return
			}
			this.namingValue = val
		},

		// 送礼物-直送
		async _giveGift(params) {
			try {
				let res = await giveGift(params)
				if (res.resultCode === '000000' && res.data) {
					console.log('发送- 直送 -成功')
					return true
				}
				return false
			} catch (error) {
				console.log(error)
				return false
			}
		},
		// 送礼物-背包
		async _giveBagGift(params) {
			try {
				let res = await giveBagGift(params)
				if (res.resultCode === '000000' && res.data) {
					console.log('发送- 背包 -成功')
					return true
				}
				return false
			} catch (error) {
				console.log(error)
				return false
			}
		},

		// 打开表情包弹窗
		openEmojiCom() {
			this.isShowEmoji = true
			this.$refs.emojiRef.getExpressList()
		},

		// 表情包发送回调事件
		async emojiSendSuccessHandle(data) {
			this.msgUrl = data.emojiInfo.msgUrl
			this.sendMessageHandle(data.emojiInfo.type)
			this.isShowEmoji = false
		},

		// 右击表情事件
		emojiRightHandle(e, index, item) {
			this.emojiRightHandleInfo = {
				index: index,
				msgUrl: item.msgUrl
			}
			this.isShowAddEmoji = true
		},

		// 添加自定义表情
		async addEmojiHandle() {
			try {
				let params = {
					imgUrl: this.emojiRightHandleInfo.msgUrl
				}
				let res = await addExpress(params)
				if (res.resultCode == '000000') {
					console.log('添加成功')
					this.$message.success("添加成功")
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取用户当前所在房间信息
		async _getUserRoom(uid) {
			try {
				let res = await getUserRoom({ uid })
				if (res.resultCode === '000000' && res.data) {
					this.userRoom = res.data
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 跳转他的直播间
		goRoomHandle() {
			let routeData = this.$router.resolve(`/room/${this.userRoom.id}/${this.userRoom.classOneId}`);
			window.open(routeData.href, '_blank');
		},
	},

	beforeDestroy() {
		bus.$off('watchPrivateChatUpdate')
	}
}
</script>

<style lang="less">
.searchCss {
	margin: 20px 12px 0;
	font-size: 14px;

	.el-input {
		input {
			width: 100%;
			height: 32px;
			background: rgba(255, 255, 255, 0.05);
			border-radius: 16px;
			border: 1px solid rgba(255, 255, 255, 0.1);

			color: #fff;
		}

		.el-input__icon {
			line-height: 32px;
		}
	}
}
</style>

<style lang="less" scoped>
.privateChatCover {
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	color: #FFFFFF;

	.privateChat {
		width: 930px;
		height: 630px;
		background: #3f314a;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
		border-radius: 16px;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-left: -465px;
		margin-top: -315px;
		overflow: hidden;
		z-index: 1;
		animation: showRightPop ease .3s;

		.userimg {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			object-fit: cover;
		}

		.userImgBox {
			position: relative;

			.count {
				position: absolute;
				top: -3px;
				left: 25px;
				min-width: 18px;
				height: 18px;
				line-height: 18px;
				text-align: center;
				background: #F56250;
				border-radius: 9px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
			}
		}


		.privareL {
			width: 234px;
			background: #19141D;

			.userlist {
				margin-top: 12px;
				height: 558px;
				overflow-y: auto;

				li {
					padding: 12px;
					margin-bottom: 6px;
					color: #998FA6;
					cursor: pointer;

					&.usersel {
						background: rgba(181, 134, 242, 0.2);
					}

					&:hover {
						background: rgba(181, 134, 242, 0.2);
					}

					.username {
						width: 116px;

						.levelIcon {
							display: block;
							width: 32px;
							margin-right: 6px;
						}

						.text {
							flex: 1;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							height: 20px;
							font-size: 14px;
							color: #fff;
						}
					}

					.user-info {
						width: 156px;
						flex-wrap: wrap;
						overflow: hidden;

						.chat-time {
							font-size: 12px;
						}

						.chat-txt {
							font-size: 14px;
							width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;

						}
					}

				}
			}
		}

		.privareR {
			width: 696px;
			position: relative;

			.chatLog {
				position: relative;
				overflow-y: auto;
				font-size: 15px;
				color: #fff;
				line-height: 21px;
				height: 516px;

				.titleDesc {
					position: sticky;
					left: 0;
					top: 0;
					width: 100%;
					height: 44px;
					line-height: 44px;
					background: #463B50;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #998FA6;
					padding: 0 16px;
					box-sizing: border-box;
					z-index: 2;

					span {
						color: #FFFFFF;
					}
				}

				.roomBox {
					position: sticky;
					left: 0;
					top: 44px;
					width: 100%;
					height: 32px;
					background: #B7CBF8;
					z-index: 2;
					cursor: pointer;

					.voice-playing {
						width: 13px;
						margin-right: 7px;
						margin-top: 0;

						div {
							background: #304F94;
							width: 3px;
						}
					}

					.text {
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #304F94;
					}
				}

				.chatItem {
					padding: 0 20px;
				}

				.chatTime {
					color: #998FA6;
					font-size: 12px;
					text-align: center;
					padding: 20px;
				}

				.otherRecords {
					margin-bottom: 16px;
					overflow: hidden;

					.userimg {
						float: left;
					}

					.messageBox {
						float: left;
						margin-left: 8px;
						background: rgba(255, 255, 255, 0.1);
						border-radius: 2px 16px 16px 16px;
						padding: 10px 14px;
						max-width: 570px;
						white-space: normal;
						word-wrap: break-word;
						word-break: break-all;

						.giftIcon {
							width: 35px;
							object-fit: contain;
						}

						.imImg {
							max-width: 256px;
							display: block;
						}

						.emojiPackBox {
							position: relative;
							display: inline-block;

							.addEmoji {
								position: absolute;
								right: -60px;
								bottom: 20%;
								width: 96px;
								height: 32px;
								line-height: 32px;
								text-align: center;
								background: #19141D;
								border: 1px solid rgba(255, 255, 255, 0.2);
								font-size: 12px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #FFFFFF;
								cursor: pointer;
							}
						}

						.emoPackImg {
							max-height: 128px;
							max-width: 128px;
							display: block;
							margin-top: 8px;
							object-fit: contain;
						}

						.emoPackImgSys {
							height: 48px;
							width: 48px;
						}
					}

					.imgMessageBox {
						background: none !important;
						padding: 0 14px;
					}
				}

				.myRecords {
					.userimg {
						float: right;
					}

					.messageBox {
						float: right;
						margin-right: 8px;
						background: #B491FC;
						border-radius: 16px 2px 16px 16px;
					}
				}
			}

			.privareSend {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 657px;
				height: 48px;
				background: #201A25;
				border-radius: 0px 0px 16px 0px;
				padding: 0 20px;

				.gift {
					width: 67px;
					height: 30px;
					background: url("../assets/imgs/lw.png") no-repeat 10px center rgba(180, 145, 252, 0.3);
					border-radius: 15px;
					margin-top: 9px;
					color: #fff;
					font-size: 14px;
					padding-left: 37px;
					line-height: 30px;
					cursor: pointer;
				}

				.chatInput {
					width: 524px;
					height: 30px;
					background: rgba(180, 145, 252, 0.1);
					border-radius: 15px;
					border: 1px solid rgba(255, 255, 255, 0.2);
					margin: 9px 0px 0 12px;
					position: relative;
					padding-left: 4px;

					.emojiIcon {
						cursor: pointer;
						width: 32px;
						height: 32px;
						background: url("../assets/imgs/emoji/icon_emoji.png") no-repeat center/cover;
						margin-right: 8px;

						&:hover {
							background: url("../assets/imgs/emoji/icon_emoji_check.png") no-repeat center/cover;
						}
					}

					input {
						width: 400px;
						background: none;
						font-size: 14px;
						color: #fff;
						outline: none;
					}

					.iconUploadImg {
						height: 24px;
						width: 24px;
						display: block;
						margin-right: 8px;
						cursor: pointer;
					}

					button {
						width: 56px;
						height: 24px;
						background: linear-gradient(90deg, #705DFF 0%, #B586F2 100%);
						border-radius: 15px;
						font-size: 14px;
						color: #fff;
						margin-right: 3px;
						cursor: pointer;
					}
				}
			}

			.h64 {
				height: 64px;
				background: #201A25;
				border-radius: 0px 16px 0px 0px;

				.username {
					color: #fff;
					font-size: 16px;
					margin-left: 20px;
					height: 64px;

					&>img {
						width: 36px;
						margin-right: 6px;
					}

					.text {
						display: block;
						max-width: 140px;
					}
				}

				.closebtn {
					color: #999;
					cursor: pointer;
					position: absolute;
					top: 18px;
					right: 20px;
					font-size: 24px;
				}
			}
		}
	}
}



.giftModal {
	width: 500px;
	height: 365px;
	background: #201A25;
	border: 1px solid #463B50;
	position: absolute;
	bottom: 48px;
	left: 234px;
	z-index: 999;

	.bagDiamondNum {
		margin: 12px 12px 0 0;

		.icon_diamond {
			height: 19px;
			width: 19px;
			display: block;
			margin-right: 4px;
		}

		.text {
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
		}
	}

	.sgiftBottom {
		width: 100%;
		height: 64px;
		border-top: 1px solid #463B50;
		align-items: center;

		.zsCont {
			width: 224px;
			height: 32px;
			align-items: center;
			margin-right: 12px;
			font-size: 16px;
			color: #fff;

			.giveNumBox {
				width: 96px;
				height: 32px;
				padding: 0 6px 0 14px;
				box-sizing: border-box;
				background: linear-gradient(139deg, #43285e, #281540);
				border-radius: 16px 0 0 16px;
				position: relative;
				cursor: pointer;

				.num {
					width: 100%;
					text-align: center;
					background: none;
					font-size: 16px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #FFFFFF;
				}

				input {
					outline-style: none;
				}

				i {
					padding: 4px;
					font-size: 9px;
					opacity: 0.5;
				}
			}

			& button {
				width: 128px;
				height: 32px;
				background: linear-gradient(139deg, #725ffe, #b385f3);
				border-radius: 0 16px 16px 0;
				font-size: 16px;
				color: #fff;
				cursor: pointer;
				border: none;
				padding: 0;
			}
		}

		.czCont {
			margin-left: 12px;
			height: 32px;
			background: linear-gradient(139deg, #43285e, #201A25);
			border-radius: 20px;
			display: flex;
			align-items: center;
			padding: 0 10px;

			img {
				width: 18px;
				margin-right: 8px;
			}

			span {
				color: #fff;
				font-size: 16px;
				margin-right: 12px;
			}

			p {
				font-size: 12px;
				color: #B586F2;
				cursor: pointer;
			}
		}

	}

	.giftcont {
		width: 485px;
		margin: 16px 0 0 12px;
		flex: 1;

		ul {
			overflow: auto;
			height: 236px;

			li {
				cursor: pointer;
				width: 64px;
				margin-right: 13px;
				text-align: center;
				margin-bottom: 12px;

				&.sel {
					.w64 {
						border: 1px solid #B586F2;
					}
				}

				.w64 {
					width: 64px;
					height: 64px;
					border-radius: 8px;
					border: 1px solid rgba(255, 255, 255, 0.05);
					position: relative;
					overflow: hidden;

					img {
						width: 64px;
						height: 64px;
						object-fit: cover;
					}

					.cs {
						position: absolute;
						top: 0;
						right: 0;
						line-height: 14px;
						min-width: 12px;
						height: 14px;
						border-radius: 4px;
						border: 1px solid #00B9FF;
						padding: 0 2px;
						font-size: 12px;
						color: #00B9FF;
					}
				}

				.giftname {
					text-align: center;
					margin-top: 6px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #998FA6;
				}

				.num {
					height: 18px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #998FA6;

					img {
						width: 12px;
						height: 12px;
						display: block;
						margin-right: 2px;
					}

				}

			}

			&.lb {
				li {
					width: 106px;
					height: 145px;
					position: relative;

					.lbImg {
						position: absolute;
						top: 0;
						left: 0;
						display: block;
						height: 145px;
						width: 106px;
					}

					.num {
						margin-top: 118px;
					}

				}
			}

			&.bx {
				li {
					width: 106px;
					min-height: 145px;
					position: relative;

					.bximg {
						position: absolute;
						top: 0;
						left: 0;
						display: block;
						height: 145px;
						width: 106px;
					}

					.num {
						margin-top: 118px;
					}
				}

				.magicianProgressBox {
					padding-top: 20px;
					position: relative;

					.iconORBS {
						display: block;
						height: 32px;
						width: 32px;
						position: relative;
						z-index: 2;
					}

					.progressBox {
						height: 23px;
						width: 80px;
						background: url(../assets/imgs/bg_mjcProgress.png)no-repeat center/cover;
						margin-left: -8px;
						padding: 2px;
						box-sizing: border-box;
						position: relative;

						.progressBar {
							width: 50%;
							height: 19px;
							background: linear-gradient(180deg, #A782FE 0%, #2B62BF 74%, #517BC1 100%);
							border-radius: 0px 12px 12px 0px;

						}

						.progressNum {
							position: absolute;
							top: 2px;
							right: 8px;
							font-size: 16px;
							font-family: Impact;
							color: #FFFFFF;
						}
					}
				}
			}

		}

		.noData {
			flex: 1;
			height: 100%;
			width: 100%;

			.iconNoData {
				width: 90px;
				object-fit: cover;
				display: block;
				margin-bottom: 24px;
			}

			.text {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.3);
				text-align: center;
			}
		}
	}

	.giftType {
		height: 32px;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 16px;
		margin: 12px 0 0 12px;

		li {
			width: 64px;
			height: 30px;
			line-height: 32px;
			text-align: center;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: rgba(255, 255, 255, 0.5);
			cursor: pointer;

			&.giftsel {
				color: #fff;
				background: url('../assets/imgs/room/bg_giftType.png')no-repeat center/cover;
				// background: linear-gradient(139deg, #9548E0 0%, #3B087D 100%);
				// border-radius: 16px;
				// border: 1px solid;
				// border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
			}
		}
	}

	.userlist {
		height: 52px;
		display: flex;
		align-items: center;
		margin-left: 11px;

		li {
			width: 36px;
			height: 36px;
			position: relative;
			border-radius: 18px;
			margin-right: 6px;
			border: 1px solid #201A25;

			&:hover {
				border: 1px solid #B586F2;
			}

			&.seluser {
				border: 1px solid #B586F2;
			}

			img:nth-child(1) {
				margin: 2px 0 0 2px;
				width: 32px;
				height: 32px;
				object-fit: cover;
				border-radius: 16px;
				cursor: pointer;
			}

			img:nth-child(2) {
				width: 12px;
				position: absolute;
				right: 0;
				bottom: 0;
			}

			i {
				font-size: 12px;
				position: absolute;
				right: 0;
				bottom: 0;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				line-height: 10px;
				text-align: center;
				background: #2A0E6F;
				font-style: normal;
			}
		}
	}

	.giveFastNumBox {
		width: auto;
		height: 40px;
		background: #201A25;
		border: 1px solid #463B50;
		padding: 0 0 0 10px;
		box-sizing: border-box;
		position: absolute;
		right: 38px;
		bottom: 68px;

		.itemBox {
			.num {
				width: 48px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				background: rgba(255, 255, 255, 0.05);
				border-radius: 4px;
				border: 1px solid rgba(151, 151, 151, 0.2);
				box-sizing: border-box;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #998FA6;
				cursor: pointer;
				margin-right: 10px;

				&.active {
					background: rgba(181, 134, 242, 0.1);
					border-radius: 4px;
					border: 1px solid #B586F2;
					color: #B586F2;
				}
			}
		}
	}
}

.namingPop {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 386px;
	background: #3F314A;
	border-radius: 16px;
	z-index: 999;

	.title {
		height: 65px;
		line-height: 65px;
		text-align: center;
		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
		// border-bottom: 1px dashed;
	}

	.line {
		height: 1px;
		background: linear-gradient(90deg, rgba(181, 134, 242, 0), rgba(181, 134, 242, 0.5), rgba(181, 134, 242, 0.5), rgba(181, 134, 242, 0));
	}

	.taskcont {
		padding: 15px;

		input {
			font-size: 16px;
			color: #fff;
			outline: none;

			width: 355px;
			height: 48px;
			background: rgba(32, 26, 37, .5);
			border-radius: 8px;
			padding: 0 16px;
			box-sizing: border-box;
		}

		/deep/ .el-input__inner {
			background: rgba(32, 26, 37, .5);
			border-radius: 8px;
			color: #FFFFFF;
			border: 1px solid rgba(32, 26, 37, .5);

			&:focus {
				border: 1px solid #B586F2 !important;
			}
		}

		/deep/ input::-webkit-input-placeholder {
			color: rgba(255, 255, 255, 0.3);
		}

		/deep/ input::-moz-input-placeholder {
			color: rgba(255, 255, 255, 0.3);
		}

		/deep/ input::-ms-input-placeholder {
			color: rgba(255, 255, 255, 0.3);
		}

		.desc {
			margin-top: 20px;
			font-size: 10px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: rgba(255, 255, 255, 0.5);
			text-align: center;
		}
	}

	.closebtn {
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 22px;
		color: #999;
	}

	.btnBox {
		height: 65px;
		margin-top: 20px;
		border-top: 1px solid rgba(70, 59, 80, 1);

		.btn {
			width: 200px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			font-size: 14px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #FFFFFF;
			background: linear-gradient(90deg, #705DFF 0%, #B586F2 100%);
			border-radius: 20px;
			cursor: pointer;
		}
	}
}

// 表情包
.emojiBox {
	position: absolute;
	left: 234px;
	bottom: 48px;
}
</style>
