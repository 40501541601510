import axios from "axios";

import {
    baseUrl,
    baseServerName
} from "@/util/api/config.js"
import store from '@/store/index'

async function uploadAvatar(file,maxSize=5) {
    let result = {};
    //用FormData传输
    let param = new FormData();
    param.append("file", file);
    //判断图片大小和格式
    if (file.size >= maxSize * 1024 * 1024) {
        result = { picture: "sobig", msg: `图片超过了${maxSize}M`, url: "" };
    } else if (!(['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type))) {
        result = { picture: "error", msg: " 只支持 jpg/png/jpeg/gif 格式图片", url: "" };
    } else {
        //设置请求头
        let config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": JSON.parse(localStorage.getItem('storeInfo')).token
            }
        };
        const { data } = await axios.post(
            `${baseUrl}/${baseServerName}/common/uploadFile`,
            param,
            config
        );
        console.log(data)
        // token失效，未登录场景
        if (data.resultCode === '000001') {
            store.dispatch("setUserInfo", null);
            store.dispatch("setToken", '');
            store.dispatch("setShowLogin", true);
            store.dispatch("setShowBindPhone", false);
            result = { picture: "error", msg: "token失效，请登录后重试", url: "" };
        }
        if (!data.data || data.resultCode !== '000000') {
            result = { picture: "error", msg: "图片上传失败，请重试", url: "" };
        } else {
            result = { picture: "success", msg: "上传成功", url: data.data.url };
        }
    }
    return result;
}
export default uploadAvatar;
