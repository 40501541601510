/**
 * 融云相关api封装
 * 
 */

import * as RongIMLib from '@rongcloud/imlib-next'
import { getmm, encrypt } from '@/util/crypto.js'

const RongCloudApi = {

    /**
     * 融云-加入聊天室(5.8.3前可用)
     */
    async joinChatRoom(targetId, count = 50) {
        try {
            let res = await RongIMLib.joinChatRoom(targetId, {
                count: count
            })
            // 加入世界聊天室成功
            if (res.code === RongIMLib.ErrorCode.SUCCESS) {
                console.log('加入聊天室成功：', targetId, res, res.code)
                return true
            } else {
                console.log('加入聊天室失败：', targetId, res.code, res.msg)
                return false
            }
        } catch (error) {
            console.log(error)
            throw error
        }
    },

    /**
     * 融云- 发送自定义类型消息
     * @param {*} msgFields 消息体
     * @param {*} targetId 聊天室id
     * @returns 
     */
    async sendRongCloudHandle_IWStatusMsg(msgFields, targetId, isAes = false) {
        const IWStatusMsg = RongIMLib.registerMessageType('RC:IWStatusMsg', true, true, [], false)

        try {
            let conversation = {
                conversationType: RongIMLib.ConversationType.CHATROOM,
                targetId: targetId
            }

            // 加密发送
            if (isAes) {
                // 生成随机密钥（key）
                let key = getmm(16)
                console.log('key:', key)
                // 生成一个随机字母
                const randomLetter = String.fromCharCode(97 + Math.floor(Math.random() * 26));
                // 插入随机字母到第十位
                const channelId = key.slice(0, 10) + randomLetter + key.slice(10);
                console.log('channelId:', channelId)
                // 密钥 加密 数据（将json转成字符串再进行加密）
                let content = encrypt(JSON.stringify(msgFields), key)
                var _msgFields = {
                    content
                }
                if (msgFields && msgFields.value) {
                    _msgFields.value = msgFields.value
                }
                console.log('加密发送：', _msgFields)

                conversation.channelId = channelId
                msgFields = _msgFields
            }

            const message = new IWStatusMsg({
                msgFields: msgFields
            })

            // 发送消息
            let res = await RongIMLib.sendMessage(conversation, message)
            if (res.code === RongIMLib.ErrorCode.SUCCESS) {
                // 消息发送成功，可以根据返回结果中的 messageId 字段将列表中的该消息状态改为发送成功。
                console.log('消息发送成功', res.data)
                return true
            } else {
                console.log('消息发送失败', res.code, res.msg)
                return false
            }

        } catch (error) {
            console.log('消息发送报错', error)
            return false
        }
    },
}

export default RongCloudApi;