<template>
	<div class="floatBox">
		<img src="../assets/imgs/xr.png" class="xr" />
		<div class="floatbg">
			<div>
				<p class="floatimg"></p>
				<span>APP</span>
				<img src="../assets/imgs/icon_dlCode.png" class="floatCode" />
			</div>
			<div class="quickRoom">
				<p class="floatimg"></p>
				<span>快速进房</span>
				<RoomList :quickRoomList="quickRoomList" />
			</div>
			<div>
				<p class="floatimg" @click="openPrivateChat"></p>
				<span>私信</span>
				<div class="count" v-if="messageUnreadCount && messageUnreadCount > 0">{{
					messageUnreadCount && messageUnreadCount > 99 ? '99+' : messageUnreadCount }}</div>
			</div>
			<div class="serverBox">
				<p class="floatimg"></p>
				<span>客服</span>
				<div class="serverPop d-flex a-center j-center">
					<img src="@/assets/imgs/QQ.png" />
					<div class="text">客服QQ：1058127957</div>
				</div>
			</div>
			<div @click="toTop()">
				<p class="floatimg"></p>
				<span>回到顶部</span>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapActions
} from "vuex";
import RoomList from '@/components/RoomList.vue';
import bus from '@/util/eventBus.js'

export default {
	components: {
		RoomList
	},
	props: {

	},
	data() {
		return {
			messageUnreadCount: this.$store.state.messageUnreadCount || 0,
			quickRoomList: this.$store.state.quickRoomList || 0,
		}
	},
	watch: {
		"$store.state.messageUnreadCount": {
			deep: true,
			handler(newVal) {
				// console.log(newVal)
				this.messageUnreadCount = newVal
			}
		},
		"$store.state.quickRoomList": {
			deep: true,
			handler(newVal) {
				// console.log(newVal)
				this.quickRoomList = newVal
			}
		},
	},

	methods: {
		...mapActions(["setShowLogin"]),
		toTop() {
			document.documentElement.scrollTop = 0;
		},
		// 打开私聊聊天框
		openPrivateChat() {
			bus.$emit('watchPrivateChat', { isPrivateChat: true })
		},
	}
}
</script>

<style lang="less">
.floatBox {
	position: fixed;
	bottom: 150px;
	right: 10px;
	z-index: 999;

	.xr {
		display: block;
		margin-bottom: -1px;
	}

	.floatbg {
		width: 66px;
		height: 386px;
		background: #3F314A;
		border-radius: 8px;
		padding-top: 22px;

		>div {
			width: 100%;
			cursor: pointer;
			margin: 0 0 22px;
			text-align: center;
			position: relative;

			&:hover {
				&:nth-child(1) img {
					display: block;
				}

				&:nth-child(1) p {
					background: url(../assets/imgs/APP-hover.png) no-repeat;
				}

				&:nth-child(2) p {
					background: url(../assets/imgs/jf2.png) no-repeat;
				}

				&:nth-child(3) p {
					background: url(../assets/imgs/xx2.png) no-repeat;
					background-size: cover;
				}

				&:nth-child(4) p {
					background: url(../assets/imgs/kf2.png) no-repeat;
				}

				&:nth-child(5) p {
					background: url(../assets/imgs/db2.png) no-repeat;
				}

				span {
					color: #B586F2;
				}
			}

			&:nth-child(1) p {
				background: url(../assets/imgs/APP.png) no-repeat;
			}

			&:nth-child(2) p {
				background: url(../assets/imgs/jf.png) no-repeat;
			}

			&:nth-child(3) p {
				background: url(../assets/imgs/xx.png) no-repeat;
				background-size: cover;
			}

			&:nth-child(4) p {
				background: url(../assets/imgs/kf.png) no-repeat;
			}

			&:nth-child(5) p {
				background: url(../assets/imgs/db.png) no-repeat;
			}

			.floatimg {
				width: 32px;
				height: 32px;
				margin: 0 auto 2px;
			}

			span {
				font-size: 12px;
				color: #fff;
			}

			.count {
				position: absolute;
				top: -3px;
				right: 10px;
				min-width: 18px;
				height: 18px;
				line-height: 18px;
				text-align: center;
				background: #F56250;
				border-radius: 9px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
			}

			.floatCode {
				width: 128px;
				position: absolute;
				top: -20px;
				right: 70px;
				display: none;
			}
		}

		.quickRoom {
			position: relative;

			&:hover {
				.roombox {
					display: block;
					top: 50%;
					left: inherit;
					right: 66px;
					transform: translateY(-50%);
				}

			}
		}

		.serverBox {
			position: relative;

			.serverPop {
				display: none;
				width: 220px;
				height: 64px;
				text-align: center;
				background: #201A25;
				border-radius: 12px;
				border: 1px solid #3F314A;
				position: absolute;
				top: 50%;
				right: 68px;
				transform: translateY(-50%);

				.text {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #999999;
				}
			}

			&:hover {
				.serverPop {
					display: flex;
				}

			}

		}
	}
}
</style>
