<template>
	<transition name="fade">
		<div class="confessionWallCardBox d-flex a-center" v-if="isShow">
			<div class="marquee-container" ref="marqueeContainer">
				<div class="marquee d-flex a-center" ref="marquee" :class="{ scrolling: shouldScroll }"
					@animationend="handleAnimationEnd" :style="marqueeStyle">
					<div class="nickName">{{ confessionInfo.nickName }}</div>
					<span>向</span>
					<div class="nickName targetNickname">{{ confessionInfo.targetNickname }}</div>
					<span>告白：</span>
					<div class="content">{{ confessionInfo.content }}</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import bus from '@/util/eventBus.js'
export default {
	name: 'ConfessionWallCardBox',
	data() {
		return {
			confessionInfo: {},// 当前展示的告白
			confessionWallList: [],// 告白跑马灯队列
			isShow: false,//是否展示
			shouldScroll: false,
			marqueeStyle: {},
		};
	},
	created() {
		// 监听告白推送事件
		bus.$on('watchConfession', data => {
			this.confessionWallList.push(data)
		})
		this.openConfessionTimer()
	},
	mounted() {
		this.checkMarquee();
		window.addEventListener('resize', this.checkMarquee);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.checkMarquee);
		clearInterval(this.ConfessionTimer)
		clearTimeout(this.timer)
		this.ConfessionTimer = null
		this.timer = null
	},
	methods: {
		// 开启告白推送事件定时器
		openConfessionTimer() {
			this.ConfessionTimer = setInterval(() => {
				if (!this.isShow && this.confessionWallList.length > 0) {
					this.confessionInfo = this.confessionWallList[0]
					this.isShow = true
					this.checkMarquee();
				}
			}, 1000);
		},
		// 跑马灯配置计算
		checkMarquee() {
			if (!this.isShow) {
				return
			}
			this.$nextTick(() => {
				const marquee = this.$refs.marquee;
				const container = this.$refs.marqueeContainer;
				this.shouldScroll = marquee.scrollWidth > container.clientWidth;
				if (this.shouldScroll) {
					const pixelScrollTime = 0.01;
					const totalPixels = marquee.scrollWidth - container.clientWidth;
					const scrollDuration = pixelScrollTime * totalPixels;
					const delayTime = 1;
					const totalDurationWithoutDelay = scrollDuration;

					this.marqueeStyle = {
						animationDuration: `${totalDurationWithoutDelay}s`,
						animationDelay: `${delayTime}s`
					};
				} else {
					this.marqueeStyle = {};
					this.updateList(1500)
				}
			});
		},
		// 跑马灯动画结束事件处理
		handleAnimationEnd() {
			console.log('动画结束')
			this.updateList(1000)
		},
		// 更新维护队列
		updateList(time) {
			this.timer = setTimeout(() => {
				this.confessionWallList.shift()
				clearTimeout(this.timer)
				this.timer = null
				this.isShow = false
			}, time)
		}
	}
};
</script>
<style scoped lang="less">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.confessionWallCardBox {
	animation: showRightPop .5s ease-in-out;
	width: 476px;
	height: 80px;
	padding: 0px 70px 0 116px;
	background: url("../assets/imgs/activity/confessionWall/bg_card.png") no-repeat;
	position: fixed;
	top: 0;
	left: 50%;
	margin-left: -238px;
	z-index: 999;
	box-sizing: border-box;
	overflow: hidden;
	white-space: nowrap;

	.marquee-container {
		width: 290px;
		height: 22px;
		line-height: 22px;
		overflow: hidden;
		white-space: nowrap;
		position: relative;
		margin-top: 15px;
	}

	.marquee {
		white-space: nowrap;
		transition: transform 0.3s;
		position: relative;
		color: #FFF;
		font-family: "PingFang HK";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		.nickName {
			color: #FFDB35;
			margin-right: 8px;
		}

		span {
			display: block;
			margin-right: 8px;
		}
	}

	.scrolling {
		position: absolute;
		left: 100%;
		animation: scroll-text linear both;
	}

	@keyframes scroll-text {
		0% {
			transform: translateX(-285px);
		}

		100% {
			transform: translateX(-100%);
		}
	}

}
</style>