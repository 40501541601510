<template>
	<div class='logincover' v-if="isLogin">
		<div class="logincom">
			<i class="el-icon-close closebtn" @click="closeFun"></i>
			<p class="dltit">登录</p>
			<form class="loginf" ref="form" :model="form">
				<div class="tel">
					<p>+86</p>
					<input placeholder="请输入手机号" maxlength="11" v-model="form.tel" />
				</div>
				<div class="yzcode">
					<input placeholder="请输入验证码" maxlength="6" v-model="form.code" />
					<p v-if="isCode" @click="getCode">发送验证码</p>
					<p v-else>重新发送 {{ count }}s</p>
				</div>
				<div class="loginBtn" @click="getUser">登录/注册</div>
				<el-checkbox v-model="form.checked" class="zd">下次自动登录</el-checkbox>
			</form>
			<div class="loginwx">
				<div @click="openWindow()">
					<p>微信登录</p>
					<img src="../assets/imgs/wx2.png" />
				</div>
				<p class="logints">未注册手机验证后自动登录，登录或注册即代表同意
					<span @click="agreeHandle('agreeView')">用户协议</span>和
					<span @click="agreeHandle('privacyPolicy')">隐私政策</span>
				</p>
				<p class="logints">未满18周岁未成年人请勿自行注册，其注册、登录帐号及使用服务需征得监护人同意</p>
			</div>
		</div>
	</div>
</template>

<script>
import store from "../store";
import { sendSms, smsLogin } from "@/util/api/api.js"
export default {
	props: {
		isLogin: {
			type: Boolean,
		},
	},
	data() {
		return {
			form: {
				tel: '',
				code: '',
				checked: true,
			},
			isCode: true,
			count: '',
			timer: null,
		}
	},
	created() {

	},
	methods: {
		openWindow() {
			// const {
			// 	href
			// } = this.$router.resolve({
			// 	name: "WxLogin",
			// 	params: {

			// 	}
			// });
			// window.open(href, "name",
			// 	"height=754, width=1277, top=0, left=2, toolbar=no, menubar=no, scrollbars=no, resizable=yes,location=no, status=no"
			// );

			console.log(this.$route)

			// 是微信端浏览器——公众号
			let APPID = "wx35ba2b520836bdfb";
			let Path = this.$route.path
			let REDIRECT_URI = encodeURIComponent(`https://gxmuchun.com/#${Path}`);
			let URL = `https://open.weixin.qq.com/connect/qrconnect?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`;
			// console.log(URL)
			window.location.href = URL;

		},

		closeFun() {
			this.form.code = ''
			this.$emit('closeLogin', false)
		},
		// 登录事件
		async getUser() {
			localStorage.removeItem('roomPasswordList')
			let flag = await this._smsLogin()
			if (flag) {
				this.closeFun()
				this.$emit('getUser', true)
			}
		},
		// 获取短信验证码
		async getCode() {
			if (this.form.tel && this.form.tel.length == 11) {
				try {
					let params = {
						mobile: this.form.tel,
						type: 1
					}
					let res = await sendSms(params)
					if (res.resultCode === '000000' && res.data) {
						let TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.isCode = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.isCode = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}
				} catch (error) {
					console.log(error)
				}

			} else {
				this.$message.error('请输入手机号');
			}
		},

		// 登录
		async _smsLogin() {
			try {
				let params = {
					account: this.form.tel,
					codeValue: this.form.code
				}
				let res = await smsLogin(params)
				if (res.resultCode === '000000') {
					console.log(res)
					if (res.data) {
						store.dispatch('setUserInfo', res.data)
						store.dispatch('setToken', res.data.token)
						store.dispatch('setShowLogin', true)
					}
					this.$message.success('登录成功')
					return true
				}
			} catch (error) {
				console.log(error)
				return false
			}
		},

		// 跳转用户协议、隐私政策
		agreeHandle(type) {
			let routeData = this.$router.resolve(`/${type}`);
			window.open(routeData.href, '_blank');
		}
	},
}
</script>

<style lang="less">
.logincover {
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;

	.logincom {
		width: 500px;
		height: 750px;
		background: url(../assets/imgs/wxbg.png) no-repeat;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -375px;
		margin-left: -250px;

		.closebtn {
			color: #fff;
			cursor: pointer;
			width: 16px;
			position: absolute;
			top: 30px;
			right: 30px;
		}

		.dltit {
			text-align: center;
			font-size: 32px;
			margin: 40px auto 70px;
			color: #fff;
		}

		.loginwx {
			width: 400px;
			margin: 50px auto 0;
			text-align: center;
			color: #fff;
			font-size: 14px;

			p {
				margin-bottom: 16px;
			}

			img {
				width: 48px;
				margin: 0 auto 34px;
			}

			.logints {
				text-align: left;

				span {
					color: #FFEC10;
					margin: 0 6px;
					text-decoration: none;
					cursor: pointer;
				}
			}
		}

		.loginf {
			color: #fff;

			.zd {
				margin-left: 50px;

				.el-checkbox__label {
					color: #fff;
				}

				.el-checkbox__inner {
					background-color: #B586F2 !important;
					border-color: #B586F2 !important;
				}

				// .el-checkbox__input{
				// 	.is-checked{
				// 		background-color:#B586F2 !important;
				// 	}
				// }
			}

			div {
				width: 400px;
				height: 64px;
				background: rgba(255, 255, 255, 0.2);
				border-radius: 54px;
				margin: 0 auto 24px;
				overflow: hidden;
				line-height: 64px;
				font-size: 24px;

				input {
					color: #fff;
					font-size: 24px;
					border: none;
					height: 23px;
					margin: 21px 0px 0 0;
					line-height: 23px;
					float: left;
					background: none;
					outline: none;
					padding-left: 30px;

					&::-webkit-input-placeholder {
						color: #947fb0;
					}
				}
			}

			.tel {
				p {
					float: left;
					font-size: 24px;
					width: 103px;
					text-align: center;
					border-right: 1px solid #725d93;
					height: 23px;
					margin: 21px 00px 0 0;
					line-height: 23px;
				}

				input {
					width: 236px;
				}
			}

			.yzcode {
				position: relative;

				p {
					position: absolute;
					top: 16px;
					right: 23px;
					width: 96px;
					height: 32px;
					border-radius: 16px;
					border: 1px solid #a593be;
					text-align: center;
					line-height: 32px;
					font-size: 14px;
					cursor: pointer;
				}
			}

			.loginBtn {
				background: linear-gradient(to right, #705DFF, #B586F2);
				font-size: 24px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
}
</style>
