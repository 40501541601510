<template>
	<div class="roombox" v-if="quickRoomList.length > 0">
		<div class="box">
			<ul class="roomtit d-flex a-center">
				<li v-for="item in quickRoomList" :key="item.id">{{ item.name }}</li>
			</ul>
			<div class="roomlist">
				<div class="roomli d-flex flex-column a-center" v-for="item in quickRoomList" :key="item.id">
					<ul>
						<li v-for="(room) in item.roomList" :key="room.id" @click="goRoom(room.id, item.id)">{{ room.name }}
						</li>
					</ul>
					<div class="roomb" @click="moreHandle(item)">全部{{ item.name }}</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RoomList',
	props: {
		quickRoomList: {
			type: Array
		}
	},
	data() {
		return {

		}
	},
	created() {

	},
	methods: {
		moreHandle(item) {
			console.log(item)
			this.$router.push({ name: 'Hall', query: { type: item.id } })
		},

		// 进入房间
		goRoom(roomId, classOneId) {
			let routeData = this.$router.resolve(`/room/${roomId}/${classOneId}`);
			window.open(routeData.href, '_blank');
		}
	}
}
</script>

<style lang="less">
.roombox {
	display: none;
	position: absolute;
	top: 80px;
	left: -480px;
	z-index: 100;

	.box {
		margin: 2px;
		max-width: 750px;
		background: #201A25;
		border-radius: 12px;
		border: 1px solid #3F314A;

		padding: 0 20px 17px;

		.roomtit {
			height: 45px;
			border-bottom: 1px solid #2f2635;
			box-sizing: border-box;

			li {
				flex: 1;
				text-align: center;
				font-size: 14px;
				color: #fff;
			}
		}

		.roomlist {
			display: flex;
			padding-top: 6px;
			height: 447px;

			.roomli {
				border-right: 1px solid #2f2635;
				position: relative;
				// margin-bottom: 6px;
				width: 125px;
				color: #fff;

				&:last-child {
					border-right: none;
				}

				ul {
					flex: 1;
					overflow: hidden;
				}

				li {
					width: 125px;
					height: 28px;
					line-height: 28px;
					padding: 0 10px;
					box-sizing: border-box;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: left;
					font-size: 12px;

					&:hover {
						background: rgba(181, 134, 242, 0.2);
						color: #B586F2;
					}
				}

				.roomb {
					width: 100px;
					height: 24px;
					background: rgba(255, 255, 255, 0.1);
					border-radius: 11px;
					text-align: center;
					line-height: 24px;
					font-size: 12px;
					line-height: 24px;
					// position: absolute;
					// bottom: -6px;
					// left: 10px;
				}
			}
		}
	}
}
</style>
