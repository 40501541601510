<template>
	<!-- 确认框 -->
	<div class="allcover" v-if="isConfirm">
		<div class="confirmCom">
			<p class="txt">{{ content.message }}</p>
			<div class="btnqrs d-flex a-center j-center">
				<button class="left" @click="cancelcClick" v-if="content.isShowCancel">{{ content.cancelText }}</button>
				<button class="right" @click="confirmClick">{{ content.confirmText }}</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'confirmCom',
	data() {
		return {
			isConfirm: true,
			content: {
				message: '确定要执行该操作吗？',
				cancelText: '取消',
				confirmText: '确定',
				isShowCancel: true,
			}

		}
	},
	methods: {
		cancelcClick() {

		},
		confirmClick() {
		},
	}
}
</script>

<style lang="less">
.confirmCom {
	width: 300px;
	background: #463B50;
	border-radius: 16px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;

	.txt {
		color: #fff;
		padding: 32px 16px 25px;
		text-align: center;
		line-height: 28px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	.btnqrs {
		padding: 5px 0;

		button {
			height: 38px;
			line-height: 38px;
			text-align: center;
			color: #908D92;
			width: 50%;
			background: none;
			font-size: 16px;
			border-right: 1px solid rgba(255, 255, 255, 0.1);
			cursor: pointer;

			&.right {
				border-right: none;
				color: #B586F2;
			}
		}
	}

}
</style>
