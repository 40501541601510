
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    userInfo: null,
    token: '',//用户登录态token
    showLogin: false, // 用于控制是否显示登录组件
    showBindPhone: false, // 用于控制是否显示绑定手机号组件
    showAuth: false, // 用于控制是否显示实名认证组件
    balance: null, // 用户余额信息
    quickRoomList: [],// 快速进入房间列表
    messageUnreadCount: 0, // 单聊消息未读数量
    keywords: [],// 需过滤关键字列表
    worldRoomMessages: [],// 世界聊天室消息列表
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo
    },
    getToken(state) {
      return state.token
    },
    getShowLogin(state) {
      return state.showLogin
    },
    getShowBindPhone(state) {
      return state.showBindPhone
    },
    getShowAuth(state) {
      return state.showAuth
    },
    getBalance(state) {
      return state.balance
    },
    getQuickRoomList(state) {
      return state.quickRoomList
    },
    getMessageUnreadCount(state) {
      return state.messageUnreadCount
    },
    getKeywords(state) {
      return state.keywords
    },
    getWorldRoomMessages(state) {
      return state.worldRoomMessages
    },
  },
  mutations: {
    setUserInfo(state, data) {
      if (data) {
        let token = state.userInfo && state.userInfo.token ? state.userInfo.token : ''
        state.userInfo = {
          ...data,
          token: data.token ? data.token : token
        }
      } else {
        state.userInfo = data
      }

    },
    setToken(state, data) {
      state.token = data//用户登录态token
    },
    setShowLogin(state, data) {
      state.showLogin = data // 用于控制是否显示登录组件
    },
    setShowBindPhone(state, data) {
      state.showBindPhone = data // 用于控制是否显示绑定手机号组件
    },
    setShowAuth(state, data) {
      state.showAuth = data // 用于控制是否显示实名认证组件
    },
    setBalance(state, data) {
      state.balance = data
    },
    setQuickRoomList(state, data) {
      state.quickRoomList = data
    },
    setMessageUnreadCount(state, data) {
      state.messageUnreadCount = data
    },
    setKeywords(state, data) {
      state.keywords = data
    },
    setWorldRoomMessages(state, data) {
      state.worldRoomMessages = data
    },
    addWorldRoomMessage(state, data) {
      state.worldRoomMessages.push(data);
      if (state.worldRoomMessages.length > 200) {
        state.worldRoomMessages.shift();
      }
    }
  },
  actions: {
    setUserInfo({ commit }, data) {
      commit('setUserInfo', data);
    },
    setToken({ commit }, data) {
      commit('setToken', data);
    },
    setShowLogin({ commit }, data) {
      commit('setShowLogin', data);
    },
    setShowBindPhone({ commit }, data) {
      commit('setShowBindPhone', data);
    },
    setShowAuth({ commit }, data) {
      commit('setShowAuth', data);
    },
    setBalance({ commit }, data) {
      commit('setBalance', data);
    },
    setQuickRoomList({ commit }, data) {
      commit('setQuickRoomList', data);
    },
    setMessageUnreadCount({ commit }, data) {
      commit('setMessageUnreadCount', data);
    },
    setKeywords({ commit }, data) {
      commit('setKeywords', data);
    },
    setWorldRoomMessages({ commit }, data) {
      commit('setWorldRoomMessages', data);
    },
    addWorldRoomMessage({ commit }, data) {
      commit('addWorldRoomMessage', data);
    }
  },
  plugins: [
    createPersistedState({
      key: 'storeInfo',
      paths: ['token'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'storeInfo',
      paths: ['worldRoomMessages'],
      storage: sessionStorage,
    }),
  ],
})

