//按需引入element组件
import {
    Container,
    Header,
    Aside,
    Main,
    Footer,
    Menu,
    MenuItem,
    MenuItemGroup,
    Input,
    Checkbox,
    Form,
    FormItem,
    Slider,
    Icon,
    Row,
    Col,
    Switch,
    Image,
    Radio,
    RadioGroup,
    Upload,
    Collapse,
    CollapseItem,
    Carousel,
    CarouselItem,
    DatePicker,
    Button,
    Select,
    Loading
} from 'element-ui';
import infiniteScroll from 'vue-infinite-scroll';

//封装组件
const element = {
    install: function (Vue) {
        Vue.use(Container)
        Vue.use(Header)
        Vue.use(Aside)
        Vue.use(Main)
        Vue.use(Footer)
        Vue.use(Menu)
        Vue.use(MenuItem)
        Vue.use(MenuItemGroup)
        Vue.use(Input)
        Vue.use(Checkbox)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Slider)
        Vue.use(Icon)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Switch)
        Vue.use(Image)
        Vue.use(Radio)
        Vue.use(RadioGroup)
        Vue.use(Upload)
        Vue.use(Collapse)
        Vue.use(CollapseItem)
        Vue.use(Carousel)
        Vue.use(CarouselItem)
        Vue.use(DatePicker)
        Vue.use(Button)
        Vue.use(Select)
        Vue.use(Loading)
        Vue.use(infiniteScroll)
    }
}
//抛出整体按需引入的组件
export default element